import React from 'react';
import { HStack, Text, VStack } from '@chakra-ui/react';
import { GLOW_GREEN, PRIMARY_COLOR, SECOND_LONG_BG_COLOR } from '../../constants/constant';
import "../../styles/common.css";

function PriceItemHeader(props) {
  const {
    text1,
    text2,
    text3,
    showTitle
  } = props;

  return (
    <>
    {showTitle?<VStack mt="50px" mb="10px" w="100%" direction="column" justify="flex-start" align="flex-start" pl="20px">
        <Text color={"white"} fontSize={"30px"} zIndex={1000}>
            {text1}
            <span className='text-image-underline'>
                {text2}
            </span>
            {text3}
        </Text>
    </VStack>:null}
    <HStack w="90%" mt={showTitle?"":"50px"} p="20px" bg={SECOND_LONG_BG_COLOR} borderTopRadius={"5px"} display={{base: "none", sm:"none",md: "none", lg: "flex", xl: "flex"}}>
        <Text w="15%" as="b" borderRight={"1px solid " + GLOW_GREEN} color={GLOW_GREEN}>Type of Entity</Text>
        <Text w="55%" as="b" borderRight={"1px solid " + GLOW_GREEN}  color={GLOW_GREEN}>Description of Service</Text>
        <Text w="15%" as="b" borderRight={"1px solid " + GLOW_GREEN}  color={GLOW_GREEN}> Unit of Measure </Text>
        <Text w="15%" as="b"  color={GLOW_GREEN}>{"Total Price (SGD)"}</Text>
    </HStack>
    </>
  )
}

export default PriceItemHeader
