import { Box } from '@chakra-ui/react';
import React from 'react';
import { LONG_BG_COLOR } from '../../constants/constant';

function DPP(props) {
  return (
    <>
    <Box
      bg={LONG_BG_COLOR}
      color="white"
      p={6}
      textAlign={"justify"}>
    <>
        {/* Hello world */}
        <div>
            <p>
            <strong>Data Protection Policy</strong>
            </p>
            <p> </p>
            <p>
            <strong>
                <em>Last Updated: 20 April 2024</em>
            </strong>
            </p>
            <p>
            1. <strong>Introduction</strong>
            </p>
            <p>
            1.1 Sequence SG BizCo Pte. Ltd. (“<strong>Sequence</strong>”, “
            <strong>we</strong>”, “<strong>us</strong>”, or “<strong>our</strong>”)
            operates the Platform and provides the Services to Users. We take our
            responsibilities under Singapore’s Personal Data Protection Act 2012 (the
            “<strong>PDPA</strong>”) seriously. We also recognise the importance of
            the personal data that Users entrust to us and believe that it is our
            responsibility to properly manage, protect and process personal data
            provided to us.
            </p>
            <p>
            1.2 This Data Protection Policy is designed to assist Users in
            understanding how we collect, use, disclose and/or process the personal
            data Users have provided to us, as well as to assist Users in making an
            informed decision before providing us with any personal data.
            </p>
            <p>
            1.3 This Data Protection Policy supplements our Terms and Conditions and
            together they set out the basis on which we collect, use, disclose and
            process any personal data we collect from Users as a User of our Platform
            and/or Services. Unless otherwise defined herein, all defined terms shall
            have the meanings given to them in our Terms and Conditions.{" "}
            </p>
            <p>
            1.4{" "}
            <strong>
                By using our Platform and/or Services, Users hereby agree to be bound by
                the terms of this Data Protection Policy. If Users do not agree with any
                term of this Data Protection Policy, Users should not use our Platform
                and/or Services.
            </strong>
            </p>
            <p>
            1.5 If Users have any queries on this policy or how we may manage,
            protect, or process personal data, our Data Protection Officer (“
            <strong>DPO</strong>”) may be contactable at{" "}
            <a href="mailto:dpo@sequence.sg">dpo@sequence.sg</a>.
            </p>
            <p>
            1.6 We reserve the right to amend the terms of this Data Protection Policy
            at our sole discretion. Any amended Data Protection Policy will be posted
            on our Platform, and we will use commercially reasonable methods to notify
            Users of such amendments. Users shall be deemed to have accepted our
            published Data Protection Policy as amended by continuing to use our
            Platform and/or Services.{" "}
            </p>
            <p>
            1.7 Our Platform may, from time to time, contain links to and from the
            websites of our partner networks, advertisers, affiliates or other third
            parties. If Users follow a link to any of these websites, Users should be
            aware that these websites have their own data protection or privacy
            policies. As these websites are not owned or operated by us, we do not
            accept any responsibility or liability for the contents of these websites
            and their data protection or privacy policies, and Users access and
            provide personal data to these third-party websites at their own risk.
            Please check these policies before submitting any personal data to any
            such websites.
            </p>
            <p>
            2. <strong>Information We Collect and How We Collect It</strong>
            </p>
            <p>2.1 We may collect and process personal data such as:</p>
            <p>
            (a) names, identification details (e.g. NRIC number, FIN number, passport
            number, biometric data), contact details, registered address, email
            address, and other information provided by Users when creating an Account;
            </p>
            <p>
            (b) financial account information such as billing address, banking details
            and other payment information;
            </p>
            <p>
            (c) copies of documents containing the data described in 2.1(a) and
            2.1(b);
            </p>
            <p>
            (d) if Users contact us for any reason, we may keep a record of that
            correspondence;
            </p>
            <p>
            (e) personal data that may be captured via any error logging and reporting
            tool that captures error report data and, at Users’ option and with Users’
            consent, send this data to us in order for us to be informed of any
            software errors or problems that may occur during the use of the Platform
            and Services; and
            </p>
            <p>
            (f) details of visits to the Platform and Services, the activities Users
            engage in when using the Platform and Services, the resources that Users
            access on or via the Platform and Services, and the data provided to us in
            connection with or ancillary to these.
            </p>
            <p>
            2.2 We collect personal data at when Users use our Platform and Services,
            including but not limited to:
            </p>
            <p>(a) when Users use our Platform or Services;</p>
            <p>(b) when Users create an Account and become a Registered User;</p>
            <p>
            (c) when we correspond with Users for whatever reason including in
            conducting KYC, due diligence, feedback, surveys or general
            correspondence; or
            </p>
            <p>(d) when Users visit our Platform for whatever reason.</p>
            <p>
            2.3 Notwithstanding the above, there will be information that we collect
            which are mandatory in order to provide our Services to Users as part of
            being regulated by the Accounting and Corporate Regulatory Authority (“
            <strong>ACRA</strong>”) and the Monetary Authority of Singapore (MAS)
            without which we would not be able to provide Users our Services. In order
            to comply with such laws and regulations, we may use independent service
            providers to help us carry out due diligence and know Users’ client
            checks. When this happens, Users may be required to provide personal data
            (such as Users’ NRIC / ID or a photo), the exact information of which may
            vary depending on the checks being carried out.{" "}
            </p>
            <p>
            2.4 If Users do not provide us with the personal data as may be required
            by us, or choose not to consent to our processing of Users’ personal data,
            we may not be able to provide some or all of our Services, or respond to
            other requests. We reserve the right to decline to provide any or all of
            our Services to Users should Users decide not to consent to our
            collection, use, disclosure and/or processing of personal data that is
            required by us.
            </p>
            <p>
            3. <strong>Cookies</strong>
            </p>
            <p>
            3.1<strong> </strong>We may use cookies on our Platform. A cookie is a
            text file that is transferred to a visitor’s device (e.g. laptop. Mobile,
            tablet) so that the Platform or website can remember who the visitor is.
            Cookies only record those areas of a Platform or website that have been
            visited by a visitor’s device and for how long.
            </p>
            <p>
            3.2 Users have the ability to accept or decline such cookies. If Users
            would like to do this, please see the help menu of Users’ browser.
            However, Users may not be able to use all the interactive features of the
            Platform and/or Services if some cookies are disabled.
            </p>
            <p>
            4. <strong>How We Use Personal Data</strong>
            </p>
            <p>
            {" "}
            We may collect, use and process Users’ personal data for one or more of
            the following purposes:
            </p>
            <p>
            (a) to facilitate Users’ use of our Platform, to operate our Platform and
            to provide our Services;
            </p>
            <p>
            (b) to enter into an agreement for the provision of Services to Users;
            </p>
            <p>
            (c) to verify Users’ identity for the purposes of processing and
            administering any account registration;
            </p>
            <p>
            (d) to conduct marketing activities including market research, customer
            profiling, customer insights and targeted marketing activities;
            </p>
            <p>
            (e) to send Users notifications and marketing messages in relation to our
            promotional events, offers, opportunities, products, benefits and
            programmes, if so consented by Users;
            </p>
            <p>
            (f) to respond to, handle, and process queries, requests, applications,
            complaints, and feedback from Users;
            </p>
            <p>
            (g) to contact Users through the contact information provided by Users in
            order to provide Users with information that Users request from us;
            </p>
            <p>
            (h) to allow other Users to interact and connect with other Users of the
            Platform, including to inform Users when another User has sent Users a
            private message;
            </p>
            <p>
            (i) to correspond with us and to collect information relating to online
            interactions with us (including, for example, Users’ IP address and the
            pages Users view) so that we can offer Users a more consistent and
            personalised experience in Users’ relationship with us;
            </p>
            <p>
            (j) to store, host and/or back up (whether for disaster recovery or
            otherwise) personal data, whether within or outside Singapore;
            </p>
            <p>(k) for record-keeping purposes;</p>
            <p>
            (l) to conduct research, analysis and development activities (including
            but not limited to data analytics, surveys and/or profiling) to improve
            the Platform and Services;
            </p>
            <p>
            (m) to notify Users of any administrative updates that are not marketing
            or advertising in nature;
            </p>
            <p>
            (n) to respond to any legal processes, pursue legal rights and remedies,
            and manage any complaints or claims;
            </p>
            <p>
            (o) to respond to requests for information from public and governmental /
            regulatory authorities, statutory boards, related companies, whether in
            Singapore or abroad, for audit, compliance, investigation and inspection
            purposes;
            </p>
            <p>
            (p) to inform Users of updates on and/or changes to our programmes,
            policies, terms and conditions, data protection policy, updates and other
            administrative information;
            </p>
            <p>
            (q) to comply with any applicable law, regulation, legal process or
            government request;
            </p>
            <p>
            (r) for any other purposes for which Users have provided the information;
            and
            </p>
            <p>
            (s) for any other incidental purposes related to or in connection with the
            purposes as set out above or otherwise described in this Data Protection
            Policy.
            </p>
            <p>
            <strong>5. Personal Data Disclosed to Us by Users</strong>
            </p>
            <p>5.1 Users represent, undertake and warrant that:</p>
            <p>
            (a) for any personal data of individuals that Users will be or may be
            disclosing or disclose to us, that Users would have prior to disclosing
            such personal data to us obtained consent from the individuals whose
            personal data are being disclosed, to:
            </p>
            <p>
            (b) permit Users to disclose the individuals’ personal data to us for the
            purposes set out in this Data Protection Policy; and
            </p>
            <p>
            (c) permit us (whether in Singapore or overseas) to collect, use, disclose
            and/or process the individuals’ personal data for the purposes set out in
            this Data Protection Policy;
            </p>
            <p>
            (d) Users shall notify the individuals whose personal data are disclosed
            to us of the relevant purposes for such disclosure;{" "}
            </p>
            <p>
            (e) any personal data of individuals that Users disclose or will be
            disclosing to us is accurate. Further, Users shall give us notice in
            writing as soon as reasonably practicable should Users be aware that any
            such personal data has been updated and/or changed after such disclosure;{" "}
            </p>
            <p>
            (f) Users shall at our request, assist us to comply with all applicable
            data protection legislation or laws, including but not limited to the
            PDPA; and
            </p>
            <p>
            (g) for any personal data of individuals that are being provided to us,
            that Users are validly acting on behalf of such individuals and that Users
            have the authority of such individuals to provide their personal data to
            us and for us to collect, use, disclose and process such personal data for
            the purposes set out in this Data Protection Policy.
            </p>
            <p>
            5.2 Without prejudice to the foregoing, Users shall ensure that Users
            comply with applicable data protection laws, and that Users will not do
            anything and not omit to do anything that will cause us to be in breach of
            any provision or requirement of such applicable data protection laws,
            whether now or in the future. Users shall, at our request, promptly do
            such things or execute such documents, as determined by us, in order to
            facilitate our compliance with the applicable data protection laws.
            </p>
            <p>
            5.3 Notwithstanding anything to the contrary, Users undertake to indemnify
            and at all times hereafter to keep us (together with our respective
            officers, employees and agents) (each an “<strong>Injured</strong>{" "}
            <strong>Party</strong>”) indemnified against any and all direct and
            indirect losses, damages, actions, proceedings, costs, claims, demands,
            liabilities (including full legal costs on a solicitor and own client
            basis) which may be suffered or incurred by the Injured Party or asserted
            against the Injured Party by any person or entity whatsoever, in respect
            of any matter or event whatsoever arising out of, in the course of, by
            reason of or in respect of:
            </p>
            <p>
            (a) any breach of any of the provisions in this clause by the relevant
            User; and/or
            </p>
            <p>
            (b) any action or omission by the relevant User, that causes us to be in
            breach of the PDPA or any other applicable law.
            </p>
            <p>
            6. <strong>Disclosure of Personal Data</strong>
            </p>
            <p>
            6.1 Personal data provided to us by Users may be used, disclosed,
            maintained, accessed, processed and/or transferred to the following third
            parties, whether sited in Singapore or outside of Singapore, for the
            purposes set out below:
            </p>
            <p> (a) our affiliates, subsidiaries, and group companies;</p>
            <p>
            (b) other users that Users have transacted with or interacted with on the
            Platform or in connection with Users’ use of the Services;
            </p>
            <p>
            (c) third party service providers which require the processing of personal
            data, for example, third party service providers which have been engaged
            by us:
            </p>
            <p>
            (i) to provide and maintain any IT equipment used to store and access
            personal data;
            </p>
            <p>(ii) to host and maintain the Platform and Services;</p>
            <p>(iii) or otherwise in connection with the provision of the Services;</p>
            <p>(d) our auditors and legal advisors;</p>
            <p>
            (e) public and governmental / regulatory authorities, statutory boards,
            industry associations, whether in Singapore or abroad; and/or
            </p>
            <p>(f) courts and other alternative dispute forums.</p>
            <p>
            6.2 In certain circumstances we may provide third parties (whether or not
            located in Singapore) with aggregate information about our Users. This may
            include information about Users’ devices, including where available Users’
            IP address, operating system and browser type, for system administration
            and to report aggregate information to our advertisers. This is anonymised
            statistical data about our users' browsing actions and patterns, and does
            not identify any individual.
            </p>
            <p>
            6.3 We may also disclose personal data in order to comply with any legal
            obligation, or in order to enforce or apply any terms and conditions
            between Users and us, or to protect the rights, property, or safety of any
            person (including for example for the purposes of fraud detection and
            prevention).
            </p>
            <p>
            7. <strong>Transfer of Personal Data Outside of Singapore</strong>
            </p>
            <p>
            7.1 The personal data that we collect from Users may be transferred to,
            used, processed and stored outside of Singapore for one or more of the
            purposes set out above. By submitting personal data and/or using the
            Platform and Services, Users agree and consent to such transfer, storing
            and/or processing.
            </p>
            <p>
            7.2 We will take reasonable steps to maintain appropriate physical,
            technical and administrative security to help prevent the loss, misuse,
            unauthorised access, disclosure or modification of personal data in our
            possession.
            </p>
            <p>
            8. <strong>Updating Users’ Information</strong>
            </p>
            <p>
            8.1 When Users submit personal data to us, Users should ensure such
            personal data is accurate, and let us know if such personal data changes
            so that we are not holding any inaccurate personal data about Users.
            </p>
            <p>
            8.2 Users may request to update or amend Users’ personal data by notifying
            us through email.
            </p>
            <p>8.3 Registered Users may change their details through their Accounts.</p>
            <p>
            9. <strong>Retention of Personal Data</strong>
            </p>
            <p>
            {" "}
            Users’ personal data will be retained by us for as long as the original
            purposes or the legal or business purposes for which Users’ personal data
            was collected continues. If retention is no longer necessary, we will use
            reasonable efforts to delete, destroy or de-identify Users’ personal data
            unless retention of the same is required to satisfy legal or regulatory
            requirements or to protect our interests or in accordance with our
            policies.
            </p>
            <p>
            10. <strong>Users’ Rights</strong>
            </p>
            <p>
            10.1 Subject to Clause 2.4 of this Data Protection Policy, Users may
            withdraw Users’ consent for us to collect, use, disclose and/or process
            Users’ personal data for some or all of the purposes listed in this Data
            Protection Policy.
            </p>
            <p>
            10.2 Users may request to access or correct the personal data currently in
            our possession by emailing our DPO using the contact details. Please note
            that we may charge Users a reasonable fee for the handling and processing
            of Users’ requests to access Users’ personal data.
            </p>
        </div>
</>
    
    </Box></>
  )
}

export default DPP
