import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from '../routes/Home';

function SeqHome(props) {
    return (
        <Routes>
            <Route path="/" exact element={<Home/>}/>
        </Routes>
    );
}

export default SeqHome;