export const AA_PRICING = [
  {
    "entityType": "All Entities",
    "typeOfService": "XBRL Filing for Financial Statements",
    "mTypeOfService": "XBRL Filing for Financial Statements",
    "unitOfMeasure": "Per Transaction",
    "totalPrice": 350
  },
  {
    "entityType": "All Entities",
    "typeOfService": "Setting up of Accounting systems (including Chart of Accounts and other processes)",
    "mTypeOfService": "Setting up of Accounting systems and Chart of Accounts",
    "unitOfMeasure": "Per Transaction",
    "totalPrice": 0
  },
  {
    "entityType": "All Entities",
    "typeOfService": "Transfer In from other Corporate Service Providers",
    "mTypeOfService": "Transfer In from other Corporate Service Providers",
    "unitOfMeasure": "Per Transaction",
    "totalPrice": 0
  },
  {
    "entityType": "All Entities",
    "typeOfService": "Bookkeeping (with a minimum package of 50 accounting transactions for a year) and Monthly and Annual (as per FYE) Unaudited Financial Statements",
    "mTypeOfService": "Bookkeeping (min. 50 accounting transactions / year) and Monthly and Annual Unaudited Financial Statements",
    "unitOfMeasure": "Per Accounting Transaction",
    "totalPrice": 5
  },
  {
    "entityType": "All Entities",
    "typeOfService": "Consolidation of Group Accounts",
    "mTypeOfService": "Consolidation of Group Accounts",
    "unitOfMeasure": "Per Transaction",
    "totalPrice": 1000
  },
  {
    "entityType": "All Entities",
    "typeOfService": "Audit of Financial Statements",
    "mTypeOfService": "Audit of Financial Statements",
    "unitOfMeasure": "Per Transaction",
    "totalPrice": 6000
  },
  {
    "entityType": "All Entities",
    "typeOfService": "GST Registration with IRAS",
    "mTypeOfService": "GST Registration with IRAS",
    "unitOfMeasure": "Per Transaction",
    "totalPrice": 360
  },
  {
    "entityType": "All Entities",
    "typeOfService": "Quarterly GST Filing with IRAS",
    "mTypeOfService": "Quarterly GST Filing with IRAS",
    "unitOfMeasure": "Per Transaction",
    "totalPrice": 320
  },
  {
    "entityType": "All Companies",
    "typeOfService": "Corporate Income Tax: Computation and Filing with IRAS for Estimated Chargeable Income (ECI)",
    "mTypeOfService": "Corporate Income Tax - IRAS Estimated Chargeable Income (ECI)",
    "unitOfMeasure": "Per Transaction",
    "totalPrice": 600
  },
  {
    "entityType": "All Companies",
    "typeOfService": "Corporate Income Tax: Computation and Filing with IRAS for Form C, Form C-S, or Form C-S (Lite)",
    "mTypeOfService": "Corporate Income Tax - IRAS Form C, Form C-S, or Form C-S (Lite)",
    "unitOfMeasure": "Per Transaction",
    "totalPrice": 800
  },
  {
    "entityType": "All Entities",
    "typeOfService": "Tax Consultancy",
    "mTypeOfService": "Tax Consultancy",
    "unitOfMeasure": "Per Transaction",
    "totalPrice": 16000
  }
]