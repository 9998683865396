// src/theme.ts
import { extendTheme } from '@chakra-ui/react';
import { PRIMARY_FONT } from './constant';

const breakpoints = {
  sm: '30em',  // 480px
  md: '48em',  // 768px
  lg: '62em',  // 992px
  xl: '80em',  // 1280px
  '2xl': '96em',  // 1536px
  // Custom breakpoints
  '3xl': '112em', // 1792px
  '4xl': '132em', // 2112px
  '5xl': '147em', // 2352px
  '6xl': '160em', //2560px
};

const theme = extendTheme({
  fonts: {
    heading: `${PRIMARY_FONT}`,
    body: `${PRIMARY_FONT}`,
  },
  breakpoints
});

export default theme;