import { Box, Image } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { LONG_BG_COLOR, RING_COLOR } from "../../constants/constant";
import bgGradient1 from "../../assets/bgGradient1.png";


const getRandomPosition = () => {
  return {
    top: `${Math.random() * 80}%`,
    left: `${Math.random() * 80}%`,
  };
};

const PlanetSystem = () => {
  const [positions, setPositions] = useState(
    Array(5).fill({ top: "0", left: "0" })
  );

  useEffect(() => {
    const newPositions = positions.map(() => getRandomPosition());
    setPositions(newPositions);
  }, []);

  const rings = Array.from({ length: 5 }, (_, i) => i + 1);

  return (
    <Box position="absolute" width="700px" height="700px" display={{ base: "none", sm:"none", md: "none", lg: "flex", xl: "flex" }}>
      {rings.map((ring, index) => (
        <Box
          bg={LONG_BG_COLOR}
          key={ring}
          position="absolute"
          width={`${1250 - index * 250}px`}
          height={`${1250 - index * 250}px`}
          border={`2px solid ${RING_COLOR}`}
          opacity={"10%"}
          borderRadius="50%"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
        </Box>
      ))}
    </Box>
  );
};

export default PlanetSystem;
