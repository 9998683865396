import { Box, SimpleGrid, Text, Button, Icon, VStack, Center, HStack, Image, Flex } from "@chakra-ui/react";
import { AiOutlineArrowRight } from "react-icons/ai";
import { GLOW_GREEN, GRAY_SERVICE_COLOR, SECONDARY_TEXT_COLOR } from "../../constants/constant";
import rightButtonIcon from "../../assets/rightButtonIcon.png";
import CSLogo from "../../assets/CSLogo.png";
import HRLogo from "../../assets/HRLogo.png";
import { useNavigate } from 'react-router-dom';

const itemSizes = {
  gridW:["98%","98%","98%","95%","98%","85%","70%"],
  hStackW:["98%","98%","98%","95%","80%","70%","70%"],
  bigScreenComps:["none","none","none","flex","flex","flex","flex"],
  smallScreenComps:["flex","flex","flex","none","none","none","none"],
  tilteTextPY:["20px","20px","20px","0px","0px","0px","0px"],
}

const ServiceCard = ({ title, description,logo, showPricingButton, pricingPage }) => {
  const navigate = useNavigate();
  return(
  <Box
    bg="gray.900"
    p={6}
    h="100%"
    borderRadius="30px"
    boxShadow="md"
    color="white"
    position="relative"
  >
    <Text fontWeight="bold" fontSize="34px" mb={4} textAlign={"left"}>
      {title}
    </Text>
    <Text color={SECONDARY_TEXT_COLOR}  textAlign={"left"}>{description}</Text>
    {logo?<Image mt={4} src={logo} boxSize={"137px"} />:null}
    {showPricingButton?<Flex>
      <Button
        mt={4}
        color={GRAY_SERVICE_COLOR}
        variant="link"
        rightIcon={<Image src={rightButtonIcon} boxSize={"30px"}/>}
        position="absolute"
        bottom="16px"
        onClick={() => {navigate(pricingPage)}}>
        View Pricing
      </Button>
    </Flex>:null}
  </Box>)
}

const ServicesGrid = () => {
  return (
    <Center>
      <Box w={itemSizes.gridW}>
          <Center>
            <Text fontSize="46px" color="white" textAlign={"left"} mb="50px" px={itemSizes.tilteTextPY}>
                One simple platform. <Text as="span" color={GLOW_GREEN}>Everything you need.</Text>
            </Text>
          </Center>
          <Center display={itemSizes.bigScreenComps}>
            <HStack w={itemSizes.hStackW}>
              <VStack w="550px">
                <Box h="450px">
                  <ServiceCard
                    logo={CSLogo}
                    showPricingButton={true}
                    pricingPage={"/csPricing"}
                    title="Corporate Secretarial"
                    description="As your company Secretary, our team of ex-lawyers in Sequence can help you manage the mandatory periodic filing requirements, maintain your statutory records, and advise on and complete any transaction that you may be intending to do, for any kind of business entity."
                  />
                </Box>
                <Box h="320px">
                  <ServiceCard
                    showPricingButton={false}
                    title="Business Consultancy"
                    description="The businesses today may often require cross-functional experience for resolution or to reach a breakthrough. Our team can assist in your business problems or business scenarios to optimise; and transactions relating to Sale & Purchase (S&P) or Mergers & Acquisitions (M&A)."
                  />
                </Box>
              </VStack>
              <VStack w="550px">
                <Box h="390px">
                  <ServiceCard
                    showPricingButton={true}
                    pricingPage={"/aaPricing"}
                    title="Accounting and Auditing"
                    description="Our team of chartered accountants in Sequence is well-versed in the Singapore Financial Reporting Standards and other accounting standards. All you will have to do is to pass over the accounting documents to us as they come to you, and the rest will be taken care of by us."
                  />
                </Box>
                <Box h="380px">
                  <ServiceCard
                    logo={HRLogo}
                    showPricingButton={false}
                    title="HR Management"
                    description="Every team member needs your attention to administer the basics - payroll, leaves, benefits, claims, work passes, taxes, provident or social security funds. We can manage these on your behalf."
                  />
                </Box>
              </VStack>
            </HStack>
          </Center>
          <Center display={itemSizes.smallScreenComps}>
              <VStack w="98%">
                <Box h="530px">
                  <ServiceCard
                    logo={CSLogo}
                    showPricingButton={true}
                    pricingPage={"/csPricing"}
                    title="Corporate Secretarial"
                    description="As your company Secretary, our team of ex-lawyers in Sequence can help you manage the mandatory periodic filing requirements, maintain your statutory records, and advise on and complete any transaction that you may be intending to do, for any kind of business entity."
                  />
                </Box>
                <Box h="390px">
                  <ServiceCard
                    showPricingButton={true}
                    pricingPage={"/aaPricing"}
                    title="Accounting and Auditing"
                    description="Our team of chartered accountants in Sequence is well-versed in the Singapore Financial Reporting Standards and other accounting standards. All you will have to do is to pass over the accounting documents to us as they come to you, and the rest will be taken care of by us."
                  />
                </Box>
                <Box h="350px">
                  <ServiceCard
                    showPricingButton={false}
                    title="Business Consultancy"
                    description="The businesses today may often require cross-functional experience for resolution or to reach a breakthrough. Our team can assist in your business problems or business scenarios to optimise; and transactions relating to Sale & Purchase (S&P) or Mergers & Acquisitions (M&A)."
                  />
                </Box>
                <Box h="390px">
                  <ServiceCard
                    logo={HRLogo}
                    showPricingButton={false}
                    title="HR Management"
                    description="Every team member needs your attention to administer the basics - payroll, leaves, benefits, claims, work passes, taxes, provident or social security funds. We can manage these on your behalf."
                  />
                </Box>
              </VStack>
          </Center>
        </Box>
    </Center>
  );
};

export default ServicesGrid;
