export const CS_PRICING = [
    {
      "entityType": "Local Company",
      "typeOfService": "Incorporation of a Local Company (exclusive of government fees)",
      "mTypeOfService": "Incorporation of a Local Company",
      "unitOfMeasure": "Per Transaction",
      "totalPrice": 300
    },
    {
      "entityType": "Local Company",
      "typeOfService": "ACRA Fee for Incorporation of a Local Company",
      "mTypeOfService": "ACRA Fee for Incorporation of a Local Company",
      "unitOfMeasure": "Per Transaction",
      "totalPrice": 300
    },
    {
      "entityType": "All Entities",
      "typeOfService": "ACRA Name Application (exclusive of government fees)",
      "mTypeOfService": "ACRA Name Application",
      "unitOfMeasure": "Per Transaction",
      "totalPrice": 0
    },
    {
      "entityType": "All Entities",
      "typeOfService": "ACRA Fee for Name Application",
      "mTypeOfService": "ACRA Fee for Name Application",
      "unitOfMeasure": "Per Transaction",
      "totalPrice": 15
    },
    {
      "entityType": "Branch of Foreign Company",
      "typeOfService": "Registration of a Branch of a Foreign Company (exclusive of government fees)",
      "mTypeOfService": "Registration of a Branch of a Foreign Company",
      "unitOfMeasure": "Per Transaction",
      "totalPrice": 2400
    },
    {
      "entityType": "Branch of Foreign Company",
      "typeOfService": "ACRA Fee for Registration of a Branch of a Foreign Company",
      "mTypeOfService": "ACRA Fee for Registration of a Branch of a Foreign Company",
      "unitOfMeasure": "Per Transaction",
      "totalPrice": 315
    },
    {
      "entityType": "Variable Capital Company",
      "typeOfService": "Incorporation of a Variable Capital Company (exclusive of government fees), including the following:\n- Reservation of name application\n- ⁠Preparation and compilation of forms (including VCR1, VCR2, VCR6, VCR7) for VCC stakeholders\n- ⁠Preparation of the first board resolution of the VCC\n- ⁠Preparation of Constitution based on the model VCC constitution\n- General advice in relation to the incorporation, and liaising with ACRA in relation to issues or queries ",
      "mTypeOfService": "Incorporation of a Variable Capital Company",
      "unitOfMeasure": "Per Transaction",
      "totalPrice": 3000
    },
    {
      "entityType": "Variable Capital Company",
      "typeOfService": "ACRA Fees for Incorporation of a Variable Capital Company",
      "mTypeOfService": "ACRA Fees for Incorporation of a VCC",
      "unitOfMeasure": "Per Transaction",
      "totalPrice": 8015
    },
    {
      "entityType": "Variable Capital Company",
      "typeOfService": "Registration of a Sub-Fund under a Variable Capital Company (exclusive of government fees)",
      "mTypeOfService": "Registration of a Sub-Fund under a VCC",
      "unitOfMeasure": "Per Transaction",
      "totalPrice": 500
    },
    {
      "entityType": "Variable Capital Company",
      "typeOfService": "ACRA Fee for Registration of a Sub-Fund under a Variable Capital Company",
      "mTypeOfService": "ACRA Fee for Registration of a Sub-Fund under a VCC",
      "unitOfMeasure": "Per Transaction",
      "totalPrice": 400
    },
    {
      "entityType": "All Entities",
      "typeOfService": "Transfer In from other Corporate Service Providers",
      "mTypeOfService": "Transfer In from other Corporate Service Providers",
      "unitOfMeasure": "Per Transaction",
      "totalPrice": 0
    },
    {
      "entityType": "All Companies",
      "typeOfService": "Named Company Secretary",
      "mTypeOfService": "Named Company Secretary",
      "unitOfMeasure": "Per Year",
      "totalPrice": 150
    },
    {
      "entityType": "All Entities",
      "typeOfService": "Maintenance of Statutory Registers and Statutory Records",
      "mTypeOfService": "Maintain Statutory Registers and Statutory Records",
      "unitOfMeasure": "Per Year",
      "totalPrice": 150
    },
    {
      "entityType": "Local Company",
      "typeOfService": "Annual General Meeting",
      "mTypeOfService": "Annual General Meeting",
      "unitOfMeasure": "Per Transaction",
      "totalPrice": 300
    },
    {
      "entityType": "Local Company",
      "typeOfService": "Annual Return (exclusive of government fees)",
      "mTypeOfService": "Annual Return",
      "unitOfMeasure": "Per Transaction",
      "totalPrice": 100
    },
    {
      "entityType": "Local Company",
      "typeOfService": "ACRA Fee for Annual Return",
      "mTypeOfService": "ACRA Fee for Annual Return",
      "unitOfMeasure": "Per Transaction",
      "totalPrice": 60
    },
    {
      "entityType": "All Entities",
      "typeOfService": "Provision of Registered Office Address including Mail Scanning Services",
      "mTypeOfService": "Provision of Registered Office Address including Mail Scanning Services",
      "unitOfMeasure": "Per Year",
      "totalPrice": 360
    },
    {
      "entityType": "All Entities",
      "typeOfService": "Mail Scanning Services for Registered Office Address",
      "mTypeOfService": "Mail Scanning Services for Registered Office Address",
      "unitOfMeasure": "Per Year",
      "totalPrice": 240
    },
    {
      "entityType": "All Companies",
      "typeOfService": "Provision of Nominee Director",
      "mTypeOfService": "Provision of Nominee Director",
      "unitOfMeasure": "Per Year",
      "totalPrice": 2400
    },
    {
      "entityType": "All Companies with Shares",
      "typeOfService": "Provision of Nominee Shareholder",
      "mTypeOfService": "Provision of Nominee Shareholder",
      "unitOfMeasure": "Per Year",
      "totalPrice": 2400
    },
    {
      "entityType": "Branch of Foreign Company",
      "typeOfService": "Provision of Authorised Representative (for branches)",
      "mTypeOfService": "Provision of Authorised Representative",
      "unitOfMeasure": "Per Year",
      "totalPrice": 2400
    },
    {
      "entityType": "All Companies",
      "typeOfService": "Customised Constitution",
      "mTypeOfService": "Customised Constitution",
      "unitOfMeasure": "Per Transaction",
      "totalPrice": 2400
    },
    {
      "entityType": "All Companies",
      "typeOfService": "Change of Business Activities (SSIC)",
      "mTypeOfService": "Change of Business Activities (SSIC)",
      "unitOfMeasure": "Per Transaction",
      "totalPrice": 60
    },
    {
      "entityType": "All Companies",
      "typeOfService": "Change of Registered Office Address",
      "mTypeOfService": "Change of Registered Office Address",
      "unitOfMeasure": "Per Transaction",
      "totalPrice": 60
    },
    {
      "entityType": "All Companies",
      "typeOfService": "Change of Financial Year End",
      "mTypeOfService": "Change of Financial Year End",
      "unitOfMeasure": "Per Transaction",
      "totalPrice": 60
    },
    {
      "entityType": "All Companies",
      "typeOfService": "Change of Company Name (exclusive of government fees)",
      "mTypeOfService": "Change of Company Name",
      "unitOfMeasure": "Per Transaction",
      "totalPrice": 60
    },
    {
      "entityType": "All Companies",
      "typeOfService": "ACRA Fee for Change of Company Name",
      "mTypeOfService": "ACRA Fee for Change of Company Name",
      "unitOfMeasure": "Per Transaction",
      "totalPrice": 15
    },
    {
      "entityType": "All Companies",
      "typeOfService": "Change of Company Type (from Private to Public or from Public to Private) (exclusive of government fees)",
      "mTypeOfService": "Change of Company Type",
      "unitOfMeasure": "Per Transaction",
      "totalPrice": 180
    },
    {
      "entityType": "All Companies",
      "typeOfService": "ACRA Fee for Change of Company Type (from Private to Public or from Public to Private)",
      "mTypeOfService": "ACRA Fee for Change of Company Type",
      "unitOfMeasure": "Per Transaction",
      "totalPrice": 40
    },
    {
      "entityType": "All Companies",
      "typeOfService": "Change in Particulars of Officers (e.g. Director, Secretary) or Auditors",
      "mTypeOfService": "Change in Particulars of Officers or Auditors",
      "unitOfMeasure": "Per Transaction",
      "totalPrice": 60
    },
    {
      "entityType": "All Companies",
      "typeOfService": "Change in Particulars of Members",
      "mTypeOfService": "Change in Particulars of Members",
      "unitOfMeasure": "Per Transaction",
      "totalPrice": 60
    },
    {
      "entityType": "All Companies",
      "typeOfService": "Addition or Amendment of Alternate Address for Officers (e.g. Director, Secretary) (exclusive of government fees)",
      "mTypeOfService": "Add/Amend Alternate Address for Officers",
      "unitOfMeasure": "Per Transaction",
      "totalPrice": 60
    },
    {
      "entityType": "All Companies",
      "typeOfService": "ACRA Fee for Addition or Amendment of Alternate Address for Officers (e.g. Director, Secretary)",
      "mTypeOfService": "ACRA Fee for Alternate Address for Officers",
      "unitOfMeasure": "Per Transaction",
      "totalPrice": 40
    },
    {
      "entityType": "All Companies",
      "typeOfService": "Provision of Alternate Address for Officers (e.g. Director, Secretary)",
      "mTypeOfService": "Provision of Alternate Address for Officers",
      "unitOfMeasure": "Per Transaction",
      "totalPrice": 120
    },
    {
      "entityType": "All Companies",
      "typeOfService": "Appointment or Resignation of Officers (e.g. Director, Secretary) or Auditors",
      "mTypeOfService": "Appointment or Resignation of Officers or Auditors",
      "unitOfMeasure": "Per Transaction",
      "totalPrice": 60
    },
    {
      "entityType": "All Companies",
      "typeOfService": "Removal of Officers (e.g. Director, Secretary) or Auditors",
      "mTypeOfService": "Removal of Officers or Auditors",
      "unitOfMeasure": "Per Transaction",
      "totalPrice": 240
    },
    {
      "entityType": "All Companies",
      "typeOfService": "EGM (Extraordinary General Meeting)",
      "mTypeOfService": "EGM (Extraordinary General Meeting)",
      "unitOfMeasure": "Per Transaction",
      "totalPrice": 360
    },
    {
      "entityType": "All Companies",
      "typeOfService": "Directors' Resolutions",
      "mTypeOfService": "Directors' Resolutions",
      "unitOfMeasure": "Per Transaction",
      "totalPrice": 60
    },
    {
      "entityType": "All Companies",
      "typeOfService": "Non-Standard Directors' Resolutions",
      "mTypeOfService": "Non-Standard Directors' Resolutions",
      "unitOfMeasure": "Per Transaction",
      "totalPrice": 300
    },
    {
      "entityType": "All Companies",
      "typeOfService": "Members' Resolutions",
      "mTypeOfService": "Members' Resolutions",
      "unitOfMeasure": "Per Transaction",
      "totalPrice": 80
    },
    {
      "entityType": "All Companies",
      "typeOfService": "Non-Standard Members' Resolutions",
      "mTypeOfService": "Non-Standard Members' Resolutions",
      "unitOfMeasure": "Per Transaction",
      "totalPrice": 300
    },
    {
      "entityType": "All Companies with Shares",
      "typeOfService": "Allotment and Issuance of Shares",
      "mTypeOfService": "Allotment and Issuance of Shares",
      "unitOfMeasure": "Per Transaction",
      "totalPrice": 360
    },
    {
      "entityType": "All Companies with Shares",
      "typeOfService": "Transfer of Shares (exclusive of IRAS Stamp Duty)",
      "mTypeOfService": "Transfer of Shares",
      "unitOfMeasure": "Per Transaction",
      "totalPrice": 360
    },
    {
      "entityType": "All Companies with Shares",
      "typeOfService": "IRAS Stamp Duty for Transfer of Shares",
      "mTypeOfService": "IRAS Stamp Duty for Transfer of Shares",
      "unitOfMeasure": "Per Transaction",
      "totalPrice": "Variable"
    },
    {
      "entityType": "All Companies with Shares",
      "typeOfService": "Share Capital Alteration",
      "mTypeOfService": "Share Capital Alteration",
      "unitOfMeasure": "Per Transaction",
      "totalPrice": 1600
    },
    {
      "entityType": "All Companies with Shares",
      "typeOfService": "Share Capital Reduction",
      "mTypeOfService": "Share Capital Reduction",
      "unitOfMeasure": "Per Transaction",
      "totalPrice": 3600
    },
    {
      "entityType": "All Companies with Shares",
      "typeOfService": "Share Buyback",
      "mTypeOfService": "Share Buyback",
      "unitOfMeasure": "Per Transaction",
      "totalPrice": 3600
    },
    {
      "entityType": "All Companies with Shares",
      "typeOfService": "Conversion of Shares",
      "mTypeOfService": "Conversion of Shares",
      "unitOfMeasure": "Per Transaction",
      "totalPrice": 2200
    },
    {
      "entityType": "All Companies with Shares",
      "typeOfService": "Declaration of Dividends (Interim or Final)",
      "mTypeOfService": "Declaration of Dividends (Interim or Final)",
      "unitOfMeasure": "Per Transaction",
      "totalPrice": 240
    },
    {
      "entityType": "All Companies",
      "typeOfService": "Declaration of Directors' Fees",
      "mTypeOfService": "Declaration of Directors' Fees",
      "unitOfMeasure": "Per Transaction",
      "totalPrice": 240
    },
    {
      "entityType": "All Entities",
      "typeOfService": "Opening or Closing of Entity Bank Account (excluding bank charges)",
      "mTypeOfService": "Opening or Closing of Entity Bank Account",
      "unitOfMeasure": "Per Transaction",
      "totalPrice": 720
    },
    {
      "entityType": "All Entities",
      "typeOfService": "Addition or Changes in Authorised Signatories of Entity Bank Account",
      "mTypeOfService": "Add/Change Authorised Signatories of Entity Bank Account",
      "unitOfMeasure": "Per Transaction",
      "totalPrice": 240
    },
    {
      "entityType": "All Entities",
      "typeOfService": "Striking Off Company (Active Company - Up to 1000 transaction/month)",
      "mTypeOfService": "Striking Off Company",
      "unitOfMeasure": "Per Transaction",
      "totalPrice": 850
    },
    {
      "entityType": "All Entities",
      "typeOfService": "Striking Off Company\n(Dormant Company)",
      "mTypeOfService": "Striking Off Company (Dormant Company)",
      "unitOfMeasure": "Per Transaction",
      "totalPrice": 450
    }
  ];