import React, { useEffect, useRef, useState } from 'react';
import { 
    Box,
    Center,
    useBoolean,
    VStack
} from '@chakra-ui/react';

import { accountingPricingPlans, corporatePricingPlans, SERVICE_INDEXES } from '../constant.js';
import { EMPTY } from '../LandingPage/Strings/en_strings.js';
import { useNavigate } from 'react-router-dom';
import Header from '../version2/components/Header/Header.js';
import { HEADER_LINKS, LONG_BG_COLOR } from '../version2/constants/constant.js';
import Banner from '../version2/components/Banner/Banner.js';
import Footer from '../version2/components/Footer/Footer.js';

import bgGradient1 from "../version2/assets/bgGradient1.png";
import Journeys from '../version2/components/Journeys/Journeys.js';
import PricingCardCarousel from '../version2/components/Pricing/PricingCardCarousel.js';
import ServicesGrid from '../version2/components/Services/ServicesGrid';
import EnquiryForm from '../version2/components/Enquiry/EnquiryForm.js';
import ClientsSection from '../version2/components/Clients/ClientsSection.js';
import SwitchButton from '../version2/components/Switch/SwitchButton.js';

const itemSizes = {
    bannerMarginTop:["90px","90px","100px","100px","100px","120px","120px"],
    bannerHeight:["1000px","1100px","1100px","1100px","1100px","1100px","1200px"],
    serviceGridHeight:["100%","100%","100%","1100px","1100px","1100px","1100px"],
}

function Home() {
    const [isPackageOn, setIsPackageOn] = useBoolean(true);
    const navigate = useNavigate();
    const itemRefs = useRef([]);
    const [isContactOpen, setIsContactOpen] = useState(false);
    const isFromHome = useRef(false);
    const pricingDetails = useRef(EMPTY);

    const handleScrollToItem = (index) => {
        if(index === SERVICE_INDEXES['Guides & FAQs']){
            navigate("/guides");
        }
        else if (itemRefs.current[index]) {
            const element = itemRefs.current[index];
            const offset = 150;
            const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
            itemRefs.current[index].scrollIntoView({
                top: elementPosition - offset,
                behavior: 'smooth'
            });
        }
    };

    useEffect(() => {
        if(!isContactOpen){
            isFromHome.current = false;
            pricingDetails.current = EMPTY;
        }
    },[isContactOpen]);

    return (
        <Box bg={LONG_BG_COLOR}>
            <Header links={HEADER_LINKS} onLinksClick={(index) => {handleScrollToItem(index)}}/>
            <Box w="100%" h={itemSizes.bannerHeight} bgImage={bgGradient1}>
                <Box w="100%" bg={LONG_BG_COLOR} h="100%">
                    <Box h={itemSizes.bannerMarginTop}/>
                    <Banner/>
                </Box>
            </Box>
            <Box w="100%" bg={LONG_BG_COLOR} h="100%" ref={(el) => (itemRefs.current[0] = el)}>
                <Journeys  onLinksClick={(index) => {handleScrollToItem(index)}}/>
            </Box>
            <Box w="100%" mt="50px" bg={LONG_BG_COLOR} h="100%">
                <Center>
                    <VStack>
                        <SwitchButton onText={"Corporate Secretarial"} offText={"Accounting"} isOn={isPackageOn} setIsOn={setIsPackageOn}/>
                        <PricingCardCarousel pricingPlans={isPackageOn? corporatePricingPlans: accountingPricingPlans} onLinksClick={(index) => {handleScrollToItem(index)}}/>
                    </VStack>
                </Center>
            </Box>
            <Box w="100%" mt="100px" bg={LONG_BG_COLOR} h={itemSizes.serviceGridHeight} ref={(el) => (itemRefs.current[5] = el)}>
                <ServicesGrid/>
            </Box>
            <Box w="100%" bg={LONG_BG_COLOR} h="100%" mt="70px">
                <ClientsSection/>
            </Box>
            <Box w="100%" bg={LONG_BG_COLOR} h="800px" mt="70px" ref={(el) => (itemRefs.current[6] = el)} scrollMarginTop={90}>
                <EnquiryForm/>
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="space-between">
                <Footer handleScrollToItem={handleScrollToItem}/>
            </Box>
        </Box>
  );
}

export default Home;