import React, { useEffect, useState } from 'react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import { Box, Flex, Text, Button, Link, Spacer, IconButton, Center, Image, useToast, useDisclosure, HStack, Stack, VStack } from "@chakra-ui/react";
import { PRIMARY_COLOR } from '../../../constant';
import { useLocation, useNavigate } from 'react-router-dom';
import whatsappIcon from '../../assets/whatsappIcon.png';
import { LONG_BG_COLOR, SEQUENCE_CONTACT_NUMBER, WHATSAPP_URL } from '../../constants/constant';

const itemSizes = {
    logo:["20px","30px","30px","40px","30px","40px","40px"],
    headerItems:["10px","15px","15px","15px","13px","17px","17px"],
    headerItemsMargin:["6px","8px","8px","8px","8px","9px","9px",],
    headerWidth:["95%","95%","95%","95%","95%","90%","90%"],
    hamburgerShow:["flex","flex","flex","flex","none","none","none"],
    showLinkItems:["none","none","none","none","flex","flex","flex"],
    bigContactDisplay:["none","none","flex","flex","flex","flex","flex"],
    smallContactDisplay:["flex","flex","none","none","none","none","none"],
    hamburgerMenuShow:["flex","flex","flex","flex","flex","none","none"],
}

function Header(props) {
    const {
        links,
        onLinksClick
    } = props;

    const toast = useToast();
    const navigate = useNavigate();
    const location = useLocation();
    const [showMenu, setShowMenu] = useState(true);
    const { isOpen, onOpen, onClose } = useDisclosure();
    

    useEffect(() => {
        setShowMenu(location.pathname === "/");
    },[location]);

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        toast({
            title: 'Success',
            description: `${text} copied to clipboard`,
            status: 'success',
            duration: 3000,
            isClosable: true,
        })
    }

    const onHeaderItemClicked = (index) => {
        if(index === 3 || index === 4){
            onLinksClick(5);
        }else if(index === 1){
            navigate('csPricing');
        }else if(index === 2){
            navigate('aaPricing');
        }else{
            onLinksClick(index);
        }
    }

    return (
        <Center>
            <Box w={itemSizes.headerWidth}
                p="5px"
                mt="12px"
                top={0}
                zIndex="1100"
                bgColor={LONG_BG_COLOR}
                border={"1px"}
                borderRadius={"12px"} 
                position="fixed">
                <Flex align="center">
                    <Text mx={"20px"} 
                    color={PRIMARY_COLOR} 
                    onClick={()=>{navigate('/')}} 
                    fontSize={itemSizes.logo}
                    cursor="pointer">sequence</Text>

                    <Flex ml={8} align="center" display={itemSizes.showLinkItems}>
                        {
                            links?.map((item, index) => {
                                return(<>
                                    <Link mx={itemSizes.headerItemsMargin} color={"#fff"} onClick={() => {onHeaderItemClicked(index)}}>
                                        <Text fontSize={itemSizes.headerItems}>{item}</Text>
                                    </Link>
                                </>)
                            })
                        }
                    </Flex>
                    <Spacer />
                    <HStack display={itemSizes.bigContactDisplay}>
                        {!showMenu?
                            <Button w="125px" 
                                    h="40px" 
                                    bg="transparent" 
                                    color={"white"} 
                                    mr="10px"
                                    boxShadow="inset 0px 0px 10px rgba(255, 255, 255, 0.25)"
                                    _hover={{ bg: PRIMARY_COLOR, color:'white' }} 
                                    onClick={()=>{navigate('/'); onClose()}}>
                                Go Home
                            </Button>
                        :null}
                        <Button w="125px" 
                                h="40px" 
                                bg="transparent" 
                                color={"white"} 
                                mr="10px"
                                boxShadow="inset 0px 0px 10px rgba(255, 255, 255, 0.25)"
                                _hover={{ bg: PRIMARY_COLOR, color:'white' }} 
                                onClick={()=>{onLinksClick(6)}}>
                            Contact Us 
                        </Button>
                        <Flex align="center" mx={itemSizes.headerItemsMargin}>
                            <Image src={whatsappIcon} alt='whatsapp' boxSize='40px' objectFit='contain' p="10px" cursor={"pointer"} onClick={() => {window.open(WHATSAPP_URL,'_blank')}}/>
                            <Text color={"white"} cursor={"pointer"} onClick={() => {copyToClipboard(SEQUENCE_CONTACT_NUMBER)}}>{SEQUENCE_CONTACT_NUMBER}</Text>
                        </Flex>
                    </HStack>
                    <Flex align="center" display={itemSizes.hamburgerShow} mx={itemSizes.headerItemsMargin}>
                        <IconButton
                            size="md"
                            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
                            aria-label="Open Menu"
                            color={"white"}
                            bgColor={"transparent"}
                            onClick={isOpen ? onClose : onOpen}/>
                    </Flex>
                </Flex>
                {isOpen ? (
                    <Box pb={4} display={itemSizes.hamburgerMenuShow} mt="30px">
                        <Center>
                            <VStack pl="100px"> 
                            {(showMenu)?
                                <>
                                {links?.map((element, index)=>{
                                    return(
                                        <Link mx={itemSizes.headerItemsMargin} color={"#fff"} onClick={() => {onHeaderItemClicked(index); onClose()}}>
                                            <Text fontSize={"15px"} textAlign={"left"}>{element}</Text>
                                        </Link>
                                    )
                                })}</>:null}
                                <Box h="100%" w="100%" mt="5px" display={itemSizes.smallContactDisplay}>
                                    <VStack>
                                        {!showMenu?
                                        <Button w="125px" 
                                                h="40px" 
                                                bg="transparent" 
                                                color={"white"} 
                                                mr="10px"
                                                boxShadow="inset 0px 0px 10px rgba(255, 255, 255, 0.25)"
                                                _hover={{ bg: PRIMARY_COLOR, color:'white' }} 
                                                onClick={()=>{navigate('/'); onClose()}}>
                                            Go Home
                                        </Button>
                                        :null}
                                        <Button w="125px" 
                                                h="40px" 
                                                bg="transparent" 
                                                color={"white"} 
                                                mr="10px"
                                                boxShadow="inset 0px 0px 10px rgba(255, 255, 255, 0.25)"
                                                _hover={{ bg: PRIMARY_COLOR, color:'white' }} 
                                                onClick={()=>{ onLinksClick(6); onClose();}}>
                                            Contact Us 
                                        </Button>
                                        <Flex align="center" mx={itemSizes.headerItemsMargin}>
                                            <Image src={whatsappIcon} alt='whatsapp' boxSize='40px' objectFit='contain' p="10px" cursor={"pointer"} onClick={() => {window.open(WHATSAPP_URL,'_blank')}}/>
                                            <Text color={"white"} cursor={"pointer"} onClick={() => {copyToClipboard(SEQUENCE_CONTACT_NUMBER)}}>{SEQUENCE_CONTACT_NUMBER}</Text>
                                        </Flex>
                                    </VStack>
                                </Box>
                            </VStack>
                        </Center>
                    </Box>
                ) : null}
            </Box>
        </Center>
    )
}

export default Header;

