import React, { useEffect, useState } from 'react';
import { Box, Flex, Text, Image, Center, Card, VStack } from '@chakra-ui/react';
import Slider from "react-slick";
import worldPic from "../../assets/worldPic.png";
import "../../styles/common.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ALL_LOGOS, COMPANY_LOGOS, ECO_SYSTEM_LOGOS, GLOW_GREEN, LARGE_SCREEN, MEDIUM_SCREEN, PRIMARY_COLOR, SMALL_SCREEN } from '../../constants/constant';
import { useScreenSize } from '../../customHooks/ScreenSizeProvider';

const itemSizes = {
  titleSize:["30px","35px","50p","50p","60p","60p","60p"],
  titleW:["98%","98%","90%","70%","70%","70%","70%"],
  tilteTextPY:["20px","20px","20px","0px","0px","0px","0px"],
  tilte2W:["95%","95%","100%","100%","100%","100%","100%"],
  smTilteDisplay:["flex","flex","none","none","none","none","none"],
  lgTilteDisplay:["none","none","flex","flex","flex","flex","flex"],
}

const ClientsSection = () => {
  const screenSize = useScreenSize();
  const [imageCount1, setImageCount1] = useState(10);
  const [imageCount2, setImageCount2] = useState(10);

  useEffect(() => {
    if(screenSize === SMALL_SCREEN){
      setImageCount1(3);
      setImageCount2(3);
    }else if(screenSize === MEDIUM_SCREEN){
      setImageCount1(4);
      setImageCount2(4);
    }else if(screenSize === LARGE_SCREEN){
      setImageCount1(7);
      setImageCount2(6)
    }else{
      setImageCount1(10);
      setImageCount2(6);
    }
  },[screenSize]);

  const eco_settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: imageCount1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false, 
    pauseOnHover: true,
  };

  const partner_settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: imageCount2,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false, 
    pauseOnHover: true,
  };

  return (
    <Flex direction="column" align="center" justify="center" p={8} height={"100%"}>
      <Box textAlign="center" w={itemSizes.titleW} mb={8}>
        <Center display={itemSizes.smTilteDisplay}>
          <VStack>
            <Text color="white" fontSize={itemSizes.titleSize}>
              We are part of a
            </Text>
            <Text fontSize={itemSizes.titleSize} color="white">
            w<span className='text-image-underline'>ider ecosy</span>stem
            </Text>
          </VStack>
        </Center>
        
        <Center display={itemSizes.lgTilteDisplay} >
          <Text fontSize={itemSizes.titleSize} color="white">
            We are part of a w<span className='text-image-underline'>ider ecosy</span>stem
          </Text>
        </Center>
        
        <Center w={itemSizes.tilte2W}>
          <Text color={"white"} mt={4} textAlign={"justify"}>
            At Sequence, we’re more than just a corporate secretary service provider. We're part of a dynamic ecosystem that brings together the best in the industry to support businesses at every stage of their growth. From handling administrative essentials to navigating complex compliance landscapes, we’ve got you covered. With a team that understands both the traditional and digital sides of corporate governance, we’re here to help you stay ahead and keep things running smoothly. 
          </Text>
        </Center>
      </Box>

      <Box mb={12} mt="-200px">
        <Image src={worldPic} alt="Ecosystem Globe" boxSize="600px"/>
      </Box>

      {/* Carousel Section */}
      <Box width="70%" mt="-300px" height={"100%"}>
        <Text fontSize="40px" color="white" textAlign="center" mb={4}>
          Trusted by our <Text as="span" color={GLOW_GREEN}>clients</Text>
        </Text>
        <Slider {...partner_settings}>
          {
            COMPANY_LOGOS.map((element, index) => {
              return(
                <div>
                  <Card border={"1px solid #7201A9"} key={index} h={{ base: "70px", md: "80px", lg: "90px", xl: "100px" }} w={{ base: "70px", md: "80px", lg: "90px", xl: "100px" }}>
                      <Image src={element} alt={"client"+ index} boxSize='100%' objectFit='contain' p="10px"/>
                  </Card>
                </div>
              )
            })
          }
        </Slider>
      </Box>
      {/* Carousel Section */}
      <Box width="70%" mt="100px" height={"100%"}>
        <Text fontSize="40px" color="white" textAlign="center" mb={4}>
          Trusted by our <Text as="span" color={GLOW_GREEN}>partners</Text>
        </Text>
        <Slider {...eco_settings}>
          {
            ECO_SYSTEM_LOGOS.map((element, index) => {
              return(
                <div>
                  <Card border={"1px solid #7201A9"} key={index} h={{ base: "70px", md: "80px", lg: "90px", xl: "100px" }} w={{ base: "70px", md: "80px", lg: "90px", xl: "100px" }}>
                      <Image src={element} alt={"client"+ index} boxSize='100%' objectFit='contain' p="10px"/>
                  </Card>
                </div>
              )
            })
          }
        </Slider>
      </Box>
    </Flex>
  );
};

export default ClientsSection;
