import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Center, Flex, Heading, Text, VStack } from '@chakra-ui/react';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import { AA_DETAILS, CS_DETAILS, LONG_BG_COLOR, PRIMARY_COLOR, SECONDARY_TEXT_COLOR } from '../constants/constant';
import { useLocation, useNavigate } from 'react-router-dom';
import { CS_PRICING } from '../constants/csPricing';
import { AA_PRICING } from '../constants/aaPricing';
import PriceItemHeader from '../components/Pricing/PricingItemHeader';
import PriceItem from '../components/Pricing/PricingItem';
import EnquiryForm from '../components/Enquiry/EnquiryForm';
import "../styles/common.css";
import AdditionalPricing from '../components/Pricing/AdditionalPricing';
import { SERVICE_INDEXES } from '../../constant';
import MobilePriceItem from '../components/Pricing/MobilePriceItem';

const itemSizes = {
    bigPricingComps:["none","none","none","none","flex","flex","flex"],
    smallPricingComps:["flex","flex","flex","flex","none","none","none"],
}

function PricingPage(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const [pricing, setPricing] = useState(CS_PRICING);
    const [title, setTitle] = useState("Corporate Secretarial");
    const [details, setDetails] = useState(CS_DETAILS);
    const [showAll, setShowAll] = useState(false);
    const itemsToShow = showAll ? pricing : pricing.slice(0, 5);
    const itemRefs = useRef([]);

    const handleScrollToItem = (index) => {
        if(index === SERVICE_INDEXES['Guides & FAQs']){
            navigate("/guides");
        }
        else if (itemRefs.current[index]) {
            itemRefs.current[index].scrollIntoView({ behavior: 'smooth' });
        }
    };
    
    useEffect(() => {
        if(location.pathname === "/csPricing"){
            setPricing(CS_PRICING);
            setTitle("Corporate Secretarial");
            setDetails(CS_DETAILS);
        }else if(location.pathname === "/aaPricing"){
            setPricing(AA_PRICING);
            setTitle("Accounting & Auditing");
            setDetails(AA_DETAILS);
        }
    },[]);
    return (
        <Box bg={LONG_BG_COLOR}>
        <Header onLinksClick={(index) => {handleScrollToItem(index)}}/>
        <Center>
        <VStack w="1281px" h="100%" mb="30px" mt="100px" display={itemSizes.bigPricingComps}>
            <VStack w="100%" direction="column" justify="flex-start" align="flex-start" pl="20px">
                <Text fontWeight="bold" bgGradient="linear(to-r, #090EDB, #E73DC4)" bgClip="text" fontSize="60px" mt="50px">{title}</Text>
                <Text fontWeight="bold" color={SECONDARY_TEXT_COLOR} textAlign={"left"} fontSize={"60px"}>Pricing</Text>
            </VStack>
            <VStack w="100%" direction="column" justify="flex-start" align="flex-start" pl="20px">
                {details.map((element,index) => {
                return(<>
                    <Text color={SECONDARY_TEXT_COLOR} w="80%" mt={index === 0?"20px":"10px"} textAlign="justify" key={index}>{element}</Text>
                </>)
                })}
            </VStack>
            <VStack w="100%" direction="column" justify="flex-start" align="flex-start" pl="20px">
                <PriceItemHeader text1={"FOR AL"} text2={"L TYPES OF COMPAN"} text3={"IES"} showTitle={true}/>
                <>{
                itemsToShow.map((element, index) => {
                    return(
                    <PriceItem element={element}/>
                    )
                })
                }
                {pricing.length > 5 && (
                    <Button onClick={() => setShowAll(!showAll)} ml="20px" mt="20px">
                    {showAll ? "Show Less" : "View More"}
                    </Button>
                )}</>
            </VStack>
            {/* <AdditionalPricing  text1={"BRANC"} text2={"H OF FOREIGN COM"} text3={"PANY"} priceList={[]}/>
            <AdditionalPricing  text1={"VARIAB"} text2={"LE CAPITAL COM"} text3={"PANY"} priceList={[]}/> */}
        </VStack>
        </Center>
        <VStack w="100%" boxShadow="0 0 20px rgba(0, 0, 0, 0.25)" mb="30px" mt="100px" borderRadius="10px" display={itemSizes.smallPricingComps}>
            <Center>
                <Heading as="h5" mt="50px"  bgGradient="linear(to-r, #090EDB, #E73DC4)" bgClip="text">{title}</Heading>
            </Center>
            {details.map((element,index) => {
            return(<>
                <Text w="90%" color={SECONDARY_TEXT_COLOR} mt={index === 0?"20px":"10px"} textAlign="justify" key={index}>{element}</Text>
            </>)
            })}
            <Center>
                <Heading as="h6" my="20px"  bgGradient="linear(to-r, #090EDB, #E73DC4)" bgClip="text">{"Pricing"}</Heading>
            </Center>
            <>{
            pricing.map((element, index) => {
                return(
                <MobilePriceItem element={element} key={index} isLastElement={pricing.length === (parseInt(index) + 1)}/>
                )
            })
            }</>
        </VStack>
        <Box w="100%" bg={LONG_BG_COLOR} h="800px" mt="80px" ref={(el) => (itemRefs.current[6] = el)} scrollMarginTop={90}>
            <EnquiryForm/>
        </Box>
        <Box display="flex" flexDirection="column" justifyContent="space-between">
            <Footer />
        </Box>
        </Box>
    )
}

export default PricingPage
