import { Center, HStack, Image, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import "../styles/common.css";
import happyRobo from '../assets/happyRobo.png';
import { 
    SECONDARY_TEXT_COLOR, 
    REACH_OUT_TEXT,
    SEQUENCE_CONTACT_NUMBER,
    SEQUENCE_CONTACT_EMAIL,
    LONG_BG_COLOR
} from '../constants/constant';
import PlanetSystem from '../components/Banner/PlanetSystem';

function MaintenancePage(props) {

    return (
        <Center  bgColor={LONG_BG_COLOR} h={{ base: "900px", sm: "900px", md: "1400px", lg: "1400px", xl: "1400px"}}>
            <VStack>
                <Text color={"white"} fontSize={{ base: "30px", sm: "30px", md: "40px", lg: "60px", xl: "60px"}} zIndex={1000}>Website under maintenance</Text>
                <VStack>
                    <PlanetSystem/>
                    <Text color={SECONDARY_TEXT_COLOR} fontSize={{ base: "18px", sm: "18px", md: "18px", lg: "24px", xl: "24px"}} zIndex={1000}>{REACH_OUT_TEXT}<br/>{`M: ${SEQUENCE_CONTACT_NUMBER}`} <br/> {`E: ${SEQUENCE_CONTACT_EMAIL}`}</Text>
                    <HStack>
                        <Image src={happyRobo} alt='whatsapp' boxSize={{ base: "250px", sm: "250px", md: "350px", lg: "548px", xl: "548px"}} objectFit='contain' p="10px" zIndex={1000}/>
                    </HStack>
                </VStack>
            </VStack>
        </Center>)
}

export default MaintenancePage;
