import { Box, HStack, useBreakpointValue, VStack } from "@chakra-ui/react";
import PricingCard from "./PricingCard";

const itemSizes = {
  smallScreenComps:["flex","flex","flex","none","none","none","none"],
  bigScreenComps:["none","none","none","flex","flex","flex","flex"],
}

function PricingCardCarousel(props) {
  const {
    pricingPlans,
    onLinksClick
  } = props;

  return (
      <><HStack
        px="5px"
        display={itemSizes.bigScreenComps}
        spacing={8}
        justify="center"
        alignItems="center"
        transition="transform 0.5s ease"
      >
        {pricingPlans.map((plan, index) => (
        <PricingCard
          key={index}
          title={plan.title}
          price={plan.price}
          feeType={plan.feeType}
          onLinksClick={onLinksClick}
          features={plan.features}
          buttonText={plan.buttonText}
          bigComment={plan.bigComment}
          smallComment={plan.smallComment}
          showPerYer={plan.showPerYer}
        />
      ))}
      </HStack>
      <VStack
      spacing={8}
      justify="center"
      alignItems="center"
      display={itemSizes.smallScreenComps}
    >
      {pricingPlans.map((plan, index) => (
      <PricingCard
        key={index}
        title={plan.title}
        price={plan.price}
        feeType={plan.feeType}
        onLinksClick={onLinksClick}
        features={plan.features}
        buttonText={plan.buttonText}
        bigComment={plan.bigComment}
        smallComment={plan.smallComment}
        showPerYer={plan.showPerYer}
      />
    ))}
    </VStack></>
  );
}

export default PricingCardCarousel;
