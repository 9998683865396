import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Box,
  Text,
  Flex,
  HStack,
  Center,
  VStack,
} from '@chakra-ui/react';
import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import { GLOW_GREEN, PRIMARY_COLOR } from '../../constants/constant';

const MobilePriceItem = (props) => {

    const {
        element
    } = props;

    const {
        entityType,
        mTypeOfService,
        mInnerDetails,
        unitOfMeasure,
        totalPrice
    } = element;

  return (
    <Flex w="90%" justify="center" display={{base: "flex", sm:"flex",md: "flex", lg: "none", xl: "none"}} boxShadow="0 0 20px rgba(0, 0, 0, 0.12)">
        <Accordion allowMultiple  w={{ base: "100%", sm:"100%", md: "60%", lg: "60%", xl: "60%" }} >
            <AccordionItem key={mTypeOfService} border="none" w="100%"  borderRadius="md">
            {({isExpanded}) => (
                <>
                    <h2>
                        <AccordionButton py={4} h="100px" border={"1px solid #5EFF9E"}>
                        { isExpanded?(<MinusIcon fontSize='12px' mr="10px" color={"white"}/>) :(<AddIcon mr="10px" fontSize='12px'  color={"white"}/>)}
                        <Box flex="1" textAlign="left" fontWeight="bold" fontSize="25px" fontFamily="Moderustic">
                            <Flex>
                                <Text fontFamily="Moderustic" mr="10px" fontSize={"sm"} w="75%" pr="10px" color={"white"}>{mTypeOfService}</Text>
                                <HStack w="25%" justify="center" ><Text fontFamily="Moderustic" fontSize={"md"} color={"white"}>{totalPrice === 0? "Free of charge": totalPrice === "Variable"? "Variable": `S$ ${totalPrice}`}</Text></HStack>
                            </Flex>
                        </Box>
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4} borderBottomLeftRadius="md" borderBottomRightRadius="md" border={"1px solid #5EFF9E"}>
                        <Flex direction={"column"}>
                            <Text as="b" mb="10px" color={GLOW_GREEN} textAlign={"left"} fontSize={"12"}>Type of Entity: <Text as="span" color="white" fontWeight={"normal"}>{entityType}</Text></Text>
                            <Text as="b" mb="10px" color={GLOW_GREEN} textAlign={"justify"}  fontSize={"12"}>Unit of Measure: <Text as="span" color="white" fontWeight={"normal"}>{unitOfMeasure}</Text></Text>
                            {(mInnerDetails)?<Text as="b" color={GLOW_GREEN} textAlign={"justify"}  fontSize={"12"}>Details: <Text as="span" color="white" fontWeight={"normal"}>{mInnerDetails}</Text></Text>:null}
                        </Flex>
                    </AccordionPanel>
                </>
            )}
        </AccordionItem>
        </Accordion>
    </Flex>
  );
};

export default MobilePriceItem;

