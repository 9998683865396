import { Box, Center, Text } from "@chakra-ui/react";
import { PRIMARY_COLOR } from "../../constants/constant";

const itemSizes = {
    buttonWidth:["285px","285px","485px","485px","485px","485px","485px"],
    buttonHeight:["50px","50px","70px","70px","70px","70px","70px"],
    toggleW:["230px","230px","250px","250px","250px","250px","250px"],
    toggleH:["48px","48px","65px","65px","65px","65px","65px"],
    textSize:["15px","15px","20px","20px","20px","20px","20px"]
}

const SwitchButton = (props) => {
    const {
        isOn,
        setIsOn,
        onText,
        offText
    } = props;

    return (
        <Box
        w={itemSizes.buttonWidth}
        h={itemSizes.buttonHeight}
        boxShadow="0px 0px 10px rgba(255, 255, 255, 0.25), inset 0px 0px 10px rgba(255, 255, 255, 0.25)"
        bg={"transparent"}
        border={`2px solid ${PRIMARY_COLOR}`} 
        borderRadius="full"
        p="5px"
        display="flex"
        alignItems="center"
        cursor="pointer"
        onClick={setIsOn.toggle}
        justifyContent={isOn ? "flex-end" : "flex-start"}
        transition="background-color 0.6s ease"
        mb="30px">
            {isOn?<Text fontSize={itemSizes.textSize} color="white" mr={"20px"} ml={"5px"}>
                {offText}
            </Text>:null}
            <Box w={itemSizes.toggleW}
                h={itemSizes.toggleH}
                bg="transparent"
                borderRadius="full"
                boxShadow="md"
                border={`2px solid ${PRIMARY_COLOR}`} 
                transition="all 0.8s ease">
                <Center>
                    <Text color="white" fontSize={itemSizes.textSize} pt={"15px"}>
                        {isOn ? onText : offText}
                    </Text>
                </Center>
            </Box>
            {!isOn?<Text fontSize={itemSizes.textSize} color="white" ml="10px">
                {onText}
            </Text>:null}
        </Box>
    );
};

export default SwitchButton;
