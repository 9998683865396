import { Box, Text, Button, Stack, Icon, Flex, HStack } from '@chakra-ui/react';
import { FiCheckCircle } from 'react-icons/fi'; // Placeholder icon for checked items
import { PRIMARY_COLOR } from '../../constants/constant';

const itemSizes = {
  smallScreenComps:["flex","flex","flex","none","none","none","none"],
  cardH:["100%","100%","100%","1800px","1350px","1150px","1150px"],
  commentH:["auto","auto","auto","180px","110px","110px","110px"],
  commentHForAA:["auto","auto","auto","180px","150px","150px","150px"],
  titleH:["70px","70px","70px","100px","70px","70px","70px"],
}

function PricingCard(props) {
  const { 
    title, 
    price,  
    feeType,
    features,
    buttonText, 
    bigComment,
    onLinksClick,
    showPerYer,
    smallComment,
    isFromHome,
    pricingDetails,
    setIsContactOpen
  } = props;

  return (
    <Box
      bgGradient="linear(to-r, #10172B, #2B0F48)"
      _hover={{ transform: "translateY(-10px)", boxShadow: "xl", cursor:"pointer", border:"1px", borderColor:PRIMARY_COLOR}}
      borderRadius="xl"
      p={8}
      h={itemSizes.cardH}
      w="full"
      maxW="350px"
      textAlign="left"
      boxShadow="xl"
      color="white"
    >
      <Flex h={itemSizes.titleH}>
        <Text fontSize="xl" fontWeight="bold" mb={4}>
          {title}
        </Text>
      </Flex>
      

      <Flex  h={showPerYer?itemSizes.commentHForAA:itemSizes.commentH}>
        <Text fontSize="md" color="gray.300" mb={4}>
          {bigComment}
        </Text>
      </Flex>

      {!showPerYer?<Text fontSize="4xl" fontWeight="bold" mb={2}>
        {price}
      </Text>:null}
      {showPerYer?<HStack mb="20px">
        <Text fontSize={"15px"}>From</Text> 
        <Text fontSize="3xl" fontWeight="bold" mb={2}>
          {price} 
        </Text>
        <Text fontSize={"15px"}>per year</Text>
        </HStack>:null}
        {showPerYer?<Flex  h={itemSizes.commentH}>
          <Text fontSize="md" color="gray.300" mb={4}>
              {feeType}
          </Text>
        </Flex>:null}

      {/* Get Started Button */}
      <Button
        bgGradient="linear(to-r, #7928CA, #FF0080)"
        color="white"
        size="lg"
        borderRadius="full"
        mb={6}
        _hover={{ bgGradient: "linear(to-r, #FF0080, #7928CA)" }}
       onClick={() => {onLinksClick(6)}}>
        {buttonText}
      </Button>

      {/* Features List */}
      <Stack spacing={4} mb={4}>
        {features?.map((element => {
          return(<>
          <Flex align="center">
            <Icon as={FiCheckCircle} color="blue.400" mr={2} />
            <Text>{element}</Text>
          </Flex>
          </>)
        }))}
      </Stack>
    </Box>
  );
}

export default PricingCard;
