import React from 'react';
import { HStack, Text } from '@chakra-ui/react';
import { PRIMARY_COLOR, SECONDARY_TEXT_COLOR } from '../../constants/constant';
import MobilePriceItem from './MobilePriceItem';

function PriceItem(props) {
    const {
        isLastElement,
        element
    } = props;

    const {
        entityType,
        typeOfService,
        unitOfMeasure,
        totalPrice
    } = element;

  return (
    <>
    <HStack w="90%" borderBottom={(!isLastElement)?"1px solid " + PRIMARY_COLOR:""} p="20px" display={{base: "none", sm:"none",md: "none", lg: "flex", xl: "flex"}}>
        <Text color={SECONDARY_TEXT_COLOR} w="15%">{entityType}</Text>
        <Text color={SECONDARY_TEXT_COLOR} w="55%">{typeOfService}</Text>
        <Text color={SECONDARY_TEXT_COLOR} w="15%">{unitOfMeasure}</Text>
        <Text color={SECONDARY_TEXT_COLOR} w="15%" as="b">{totalPrice === 0? "Free of charge": totalPrice === "Variable"? "Variable": `S$ ${totalPrice}`}</Text>
    </HStack>
    <MobilePriceItem element={element}/>    
    </>
  )
}

export default PriceItem;