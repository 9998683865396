import { Box, Text, Image } from '@chakra-ui/react';
import { PRIMARY_COLOR } from '../../constants/constant';

const itemSizes = {
  cardW:["350px","373px","373px","373px","373px","373px","373px"],
}

function SupportCard(props) {
    const {
        text,
        iconImage,
        title
    } = props;

  return (
    <Box
      w={itemSizes.cardW}
      display="flex"
      alignItems="center"
      bg="rgba(0, 0, 0, 0.5)" // Semi-transparent background
      px={4}
      py={3}
      borderRadius="lg"
      boxShadow="lg"
      border={`2px solid ${PRIMARY_COLOR}`}>
      <Box
        mr="15px"
        display="flex"
        alignItems="center"
        justifyContent="center">
        <Image src={iconImage} alt='iconImage' h="44px" w="44px" objectFit='contain'/>
      </Box>
      <Box>
        <Text fontWeight="bold" color="white" fontSize="md" textAlign={"left"}>
          {title}
        </Text>
        <Text color="gray.200" fontSize="sm" textAlign={"left"}>
          {text}
        </Text>
      </Box>
    </Box>
  );
}

export default SupportCard;
