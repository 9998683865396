import { Box, Center, Flex, HStack, Image, Link, Stack, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import facebookIcon from "../../assets/socials/facebookIcon.png";
import instagramIcon from "../../assets/socials/instagramIcon.png";
import linkedInIcon from "../../assets/socials/linkedInIcon.png";
import tiktokIcon from "../../assets/socials/tiktokIcon.png";
import youtubeIcon from "../../assets/socials/youtubeIcon.png";
import { useLocation, useNavigate } from 'react-router-dom';
import { 
    ADDRESS_1, 
    ADDRESS_2, 
    ADDRESS_BUSINESS_NAME, 
    ADDRESS_BUSINESS_NUMBER, 
    OPENING_HOURS, 
    SEQUENCE_CONTACT_EMAIL, 
    SEQUENCE_CONTACT_NUMBER, 
    SEQUENCE_TITLE,
    DPP_NAV_TEXT,
    TOS_NAV_TEXT
} from '../../constants/constant';

const itemSizes = {
    smallScreenComps:["flex","flex","flex","none","none","none","none"],
    bigScreenComps:["none","none","none","flex","flex","flex","flex"],
    bigScreenW:["90%","90%","90%","90%","80%","60%","50%"],
}

function Footer(props) {
    const {
        handleScrollToItem
    } = props;
    const navigate = useNavigate();

    return (
        <Box w="100%"
             py="100px"
             as="footer" 
             bgColor={"black"}>
                <Center h="100%">
                    <Box w={itemSizes.bigScreenW} h="100%">
                        <HStack h="100%" display={itemSizes.bigScreenComps}>
                            <Flex w="50%" h="100%" alignItems={"center"} pl="10px">
                                <VStack>
                                    <Flex>
                                        <Text color={"white"} fontSize={"30px"} textAlign={"left"}>{ADDRESS_BUSINESS_NAME}</Text>
                                    </Flex>
                                    <Flex w="100%">
                                        <Text color={"white"} fontSize={"20px"} textAlign={"left"}>{ADDRESS_BUSINESS_NUMBER}</Text>
                                    </Flex>
                                    <Flex w="100%" mt="50px">
                                        <Text color={"white"} fontSize={"20px"} textAlign={"left"}>{"Address:"}</Text>
                                    </Flex>
                                    <Flex w="100%">
                                        <Text color={"white"} fontSize={"20px"} textAlign={"left"}>{ADDRESS_1}</Text>
                                    </Flex>
                                    <Flex w="100%">
                                        <Text color={"white"} fontSize={"20px"} textAlign={"left"}>{ADDRESS_2}</Text>
                                    </Flex>
                                    <Flex w="100%" mt="40px">
                                        <Text color={"white"} fontSize={"20px"} textAlign={"left"}>{"Opening Hours:"}</Text>
                                    </Flex>
                                    <Flex w="100%">
                                        <Text color={"white"} fontSize={"20px"} textAlign={"left"}>{OPENING_HOURS}</Text>
                                    </Flex>
                                </VStack>
                            </Flex>
                            <Flex w="50%" h="100%" alignItems={"center"} pr="10px">
                                <VStack w="100%">
                                    <Flex w="100%" justifyContent={"flex-end"}>
                                        <Text color={"white"} fontSize={"40px"} textAlign={"right"}>{SEQUENCE_TITLE}</Text>
                                    </Flex>
                                    <Flex w="100%" justifyContent={"flex-end"}>
                                        <Text color={"white"} fontSize={"20px"} textAlign={"right"}>{`M: ${SEQUENCE_CONTACT_NUMBER}`}</Text>
                                    </Flex>
                                    <Flex w="100%" justifyContent={"flex-end"}>
                                        <Text color={"white"} fontSize={"20px"} textAlign={"right"}>{`E: ${SEQUENCE_CONTACT_EMAIL}`}</Text>
                                    </Flex>
                                    <Flex w="100%" justifyContent={"flex-end"} mt="50px">
                                        <Stack direction="row" spacing={"20px"}>
                                            <Link href="https://www.linkedin.com/company/sequence-sg/" isExternal>
                                                <Image src={linkedInIcon} alt='linkedInIcon' boxSize='30px' objectFit='contain'/>
                                            </Link>
                                            <Link href="https://www.instagram.com/sequence.singapore/" isExternal>
                                                <Image src={instagramIcon} alt='instagramIcon' boxSize='30px' objectFit='contain'/>
                                            </Link>
                                            <Link href="https://www.tiktok.com/@sequencesg?is_from_webapp=1&sender_device=pc" isExternal>
                                                <Image src={tiktokIcon} alt='tiktokIcon' boxSize='30px' objectFit='contain'/>
                                            </Link>
                                            <Link href="https://www.youtube.com/@SequenceSG" isExternal>
                                                <Image src={youtubeIcon} alt='youtubeIcon' boxSize='30px' objectFit='contain'/>
                                            </Link>
                                            <Link href="https://www.facebook.com/share/1DKvz3idDA9AmvGU/?mibextid=LQQJ4d" isExternal>
                                                <Image src={facebookIcon} alt='facebookIcon' boxSize='30px' objectFit='contain' />
                                            </Link>
                                        </Stack>
                                    </Flex>
                                    <Flex w="100%" justifyContent={"flex-end"} mt="10px" mb="100px">
                                        <Text _hover={{cursor:"pointer", textDecoration:"underline"}} color={"white"} fontSize={"20px"} textAlign={"right"} mr="15px" onClick={()=>{navigate("/dpp")}}>{DPP_NAV_TEXT}</Text>
                                        <Text color={"white"} fontSize={"20px"} textAlign={"right"} >{"  |  "}</Text>
                                        <Text _hover={{cursor:"pointer", textDecoration:"underline"}} color={"white"} fontSize={"20px"} textAlign={"right"} ml="15px" onClick={()=>{navigate("/tos")}}>{TOS_NAV_TEXT}</Text>
                                    </Flex>
                                </VStack>
                            </Flex>
                        </HStack>
                        <VStack h="100%" display={itemSizes.smallScreenComps}>
                            <Flex w="100%" h="100%" alignItems={"center"}>
                                <VStack>
                                    <Flex>
                                        <Text color={"white"} fontSize={"30px"} textAlign={"left"}>{ADDRESS_BUSINESS_NAME}</Text>
                                    </Flex>
                                    <Flex w="100%">
                                        <Text color={"white"} fontSize={"20px"} textAlign={"left"}>{ADDRESS_BUSINESS_NUMBER}</Text>
                                    </Flex>
                                    <Flex w="100%" mt="50px">
                                        <Text color={"white"} fontSize={"20px"} textAlign={"left"}>{"Address:"}</Text>
                                    </Flex>
                                    <Flex w="100%">
                                        <Text color={"white"} fontSize={"20px"} textAlign={"left"}>{ADDRESS_1}</Text>
                                    </Flex>
                                    <Flex w="100%">
                                        <Text color={"white"} fontSize={"20px"} textAlign={"left"}>{ADDRESS_2}</Text>
                                    </Flex>
                                    <Flex w="100%" mt="40px">
                                        <Text color={"white"} fontSize={"20px"} textAlign={"left"}>{"Opening Hours:"}</Text>
                                    </Flex>
                                    <Flex w="100%">
                                        <Text color={"white"} fontSize={"20px"} textAlign={"left"}>{OPENING_HOURS}</Text>
                                    </Flex>
                                </VStack>
                            </Flex>
                            <Flex w="100%" h="100%" alignItems={"center"}>
                                <VStack w="100%">
                                    <Flex w="100%">
                                        <Text color={"white"} fontSize={"40px"} textAlign={"right"}>{SEQUENCE_TITLE}</Text>
                                    </Flex>
                                    <Flex w="100%">
                                        <Text color={"white"} fontSize={"20px"} textAlign={"right"}>{`M: ${SEQUENCE_CONTACT_NUMBER}`}</Text>
                                    </Flex>
                                    <Flex w="100%">
                                        <Text color={"white"} fontSize={"20px"} textAlign={"right"}>{`E: ${SEQUENCE_CONTACT_EMAIL}`}</Text>
                                    </Flex>
                                    <Flex w="100%" mt="50px">
                                        <Stack direction="row" spacing={"20px"}>
                                            <Link href="https://www.linkedin.com/company/sequence-sg/" isExternal>
                                                <Image src={linkedInIcon} alt='linkedInIcon' boxSize='30px' objectFit='contain'/>
                                            </Link>
                                            <Link href="https://www.instagram.com/sequence.singapore/" isExternal>
                                                <Image src={instagramIcon} alt='instagramIcon' boxSize='30px' objectFit='contain'/>
                                            </Link>
                                            <Link href="https://www.tiktok.com/@sequencesg?is_from_webapp=1&sender_device=pc" isExternal>
                                                <Image src={tiktokIcon} alt='tiktokIcon' boxSize='30px' objectFit='contain'/>
                                            </Link>
                                            <Link href="https://www.youtube.com/@SequenceSG" isExternal>
                                                <Image src={youtubeIcon} alt='youtubeIcon' boxSize='30px' objectFit='contain'/>
                                            </Link>
                                            <Link href="https://www.facebook.com/share/1DKvz3idDA9AmvGU/?mibextid=LQQJ4d" isExternal>
                                                <Image src={facebookIcon} alt='facebookIcon' boxSize='30px' objectFit='contain' />
                                            </Link>
                                        </Stack>
                                    </Flex>
                                    <Flex w="100%" mt="10px" mb="100px" flexDirection={"column"}>
                                        <Text textDecoration={"underline"} color={"white"} fontSize={"20px"} textAlign={"left"} onClick={()=>{navigate("/dpp")}}>{DPP_NAV_TEXT}</Text>
                                        <Text textDecoration={"underline"} color={"white"} fontSize={"20px"} textAlign={"left"} onClick={()=>{navigate("/tos")}}>{TOS_NAV_TEXT}</Text>
                                    </Flex>
                                </VStack>
                            </Flex>
                        </VStack>
                    </Box>
                </Center>
        </Box>
    )
}
export default Footer
