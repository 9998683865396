import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SeqHome from './LandingPage/SeqHome.js';
import { ChakraProvider } from '@chakra-ui/react';
import AppPage from './routes/AppPage.js';
import ScrollToTop from './experiment/ScrollToTop.js';
import Guides from './routes/Guides.js';
import theme from './theme.js';
import MaintenancePage from './version2/routes/MaintenancePage.js';
import PricingPage from './version2/routes/PricingPage.js';
import DocumentView from './version2/routes/DocumentView.js';
import { ScreenSizeProvider } from './version2/customHooks/ScreenSizeProvider.js';

function App() {

  return (
    <div className="App">
      <ChakraProvider theme={theme}>
        <ScreenSizeProvider>
          <Router basename='/'>
            <ScrollToTop />
            <Routes>
              <Route path="/" element={<SeqHome/>}/>
              {/* <Route path="/" element={<MaintenancePage/>}/> */}
              <Route path="/appPage" element={<AppPage/>} />
              <Route path="/csPricing" element={<PricingPage/>} />
              <Route path="/aaPricing" element={<PricingPage/>} />
              <Route path="/tos" element={<DocumentView/>} />
              <Route path="/dpp" element={<DocumentView/>} />
              {/* <Route path="/guides" element={<Guides/>} /> */}
            </Routes>
          </Router>
        </ScreenSizeProvider>
      </ChakraProvider>
    </div>
  );
}

export default App;
