import { Box, Flex, Text, Center } from '@chakra-ui/react';
import { 
  GLOW_GREEN,
  JOURNEY_1_CONTENT_1, 
  JOURNEY_1_CONTENT_2, 
  JOURNEY_1_LINK_TEXT, 
  JOURNEY_1_TITLE, 
  JOURNEY_2_CONTENT_1, 
  JOURNEY_2_CONTENT_2, 
  JOURNEY_2_LINK_TEXT,
  JOURNEY_2_TITLE, 
  SECONDARY_TEXT_COLOR 
} from '../../constants/constant';
import JourneyCard from './JourneyCard';
import registerNewBusiness from "../../assets/registerNewBusiness.png";
import switchToSequenceIcon from "../../assets/switchToSequenceIcon.png";


const itemSizes = {
  titleTextSize:["30px","30px","30px","40px","40px","40px","40px"],
  titleText2Size:["18px","18px","18px","24px","24px","24px","24px"],
}

function Journeys(props) {
  const {
    onLinksClick
  } = props;

  return (
    <Box color="white" py={10} px={4} textAlign="center">
      <Text fontSize={itemSizes.titleTextSize} mb={2}>
        Start your journey <br/> with us<Text as="span" color={GLOW_GREEN}> today</Text>
      </Text>
      <Text color={SECONDARY_TEXT_COLOR} mb={8} fontSize={itemSizes.titleText2Size}>
        We offer flexible services to support you at every stage of your business journey
      </Text>

      <Center>
        <Flex
          justify="center"
          align="center"
          gap={6}
          flexWrap="wrap"
          maxW="1200px">
          <JourneyCard title={JOURNEY_1_TITLE}
              iconImage={registerNewBusiness}
              contents={[JOURNEY_1_CONTENT_1,JOURNEY_1_CONTENT_2]}
              linkText={JOURNEY_1_LINK_TEXT}
              onLinksClick={onLinksClick}/>
          <JourneyCard title={JOURNEY_2_TITLE}
              iconImage={switchToSequenceIcon}
              contents={[JOURNEY_2_CONTENT_1,JOURNEY_2_CONTENT_2]}
              linkText={JOURNEY_2_LINK_TEXT}
              onLinksClick={onLinksClick}/>
        </Flex>
      </Center>
    </Box>
  );
}

export default Journeys;
