import xpressFlowers from "../assets/clients/xpressFlowers.png";
import kingstonClinic from "../assets/clients/kingstonClinic.png";
import qci from "../assets/clients/qci.png";
import tanglineDental from "../assets/clients/tanglineDental.png";
import smileFocus from "../assets/clients/smileFocus.png";
import tenderObjects from "../assets/clients/tenderObjects.png";

import acra from "../assets/ecosystem/acra.png";
import dbsBank from "../assets/ecosystem/dbsBank.png";
import empHero from "../assets/ecosystem/empHero.png";
import isca from "../assets/ecosystem/isca.png";
import manulifeFinancial from "../assets/ecosystem/manulifeFinancial.png";
import ocbc from "../assets/ecosystem/ocbc.png";
import sgDigital from "../assets/ecosystem/sgDigital.png";
import xero from "../assets/ecosystem/xero.png";

export const isLocalhost = window.location.hostname === 'localhost';
export const SEND_EMAIL_URL = isLocalhost ? "http://localhost:3001/api/v1/send-email": "https://api.sequence.sg/api/v1/send-email";

//Screen sizes 
export const SMALL_SCREEN = "sm";
export const MEDIUM_SCREEN = "md";
export const LARGE_SCREEN = "lg";
export const XL_SCREEN = "xl";
export const XXL_SCREEN = "2xl";
export const XXXL_SCREEN = "3xl";
export const XXXXL_SCREEN = "4xl";

export const HEADER_LINKS = [
    "Incorporation",
    "Corporate Secretarial",
    "Accounting & Audit",
    "HR Management",
    "Business Consultancy",
    // "Guides & FAQs",
];

export const SEQUENCE_TITLE = "sequence";
export const SEQUENCE_CONTACT_NUMBER = "+65 8138 9137";
export const SEQUENCE_CONTACT_EMAIL = "info@sequence.sg";


//URL constant

export const WHATSAPP_URL = "https://wa.me/+6581389137";
export const PDF_WORKER_URL = "https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js";


//Colors
export const PRIMARY_COLOR = "#7201A9";
export const SECONDARY_TEXT_COLOR = "#CAD1E9";
export const LONG_BG_COLOR = "#0E0C15";
export const GLOW_GREEN = "#5EFF9E";
export const RING_COLOR = "#CAC6DD";
export const TEXTFIELD_BORDER_COLOR = "#05260F";
export const GRAY_700_COLOR = "#2D3748";
export const GRAY_SERVICE_COLOR = "#B3B3B3";
export const SECOND_LONG_BG_COLOR = "#13111A";

export const COMPANY_LOGOS = [
    xpressFlowers,
    kingstonClinic,
    qci,
    tanglineDental,
    smileFocus,
    tenderObjects
];

export const ECO_SYSTEM_LOGOS = [
    acra,
    dbsBank,
    empHero,
    isca,
    manulifeFinancial,
    ocbc,
    sgDigital,
    xero
];


export const ALL_LOGOS = [...ECO_SYSTEM_LOGOS, ...COMPANY_LOGOS];


//Text 
export const EMPTY = "";
export const SEQUENCE_BANNER_TEXT_1 = "Sequence helps you to focus on your core business";
export const SEQUENCE_BANNER_TEXT_2 = "We simplify incorporation, corporate secretarial, and accounting services in";
export const SEQUENCE_BANNER_TEXT_3 = "Singapore with transparent pricing, all through our easy-to-use digital platforms.";

export const SUPPORT_TEXT_TITLE_1 = "Outstanding Customer Support";
export const SUPPORT_TEXT_TEXT_1 = "We are always here to help you in every step of your business.";

export const SUPPORT_TEXT_TITLE_2 = "Digitalized Solutions";
export const SUPPORT_TEXT_TEXT_2 = "Enjoy the convenience of a fully digital experience with no paper involved.";

export const SUPPORT_TEXT_TITLE_3 = "Transparent Pricing";
export const SUPPORT_TEXT_TEXT_3 = "We provide transparent pricing with no hidden costs.";

export const ADDRESS_BUSINESS_NAME = "Sequence SG Bizco Pte. Ltd";
export const ADDRESS_BUSINESS_NUMBER = "UEN 202346260R | FA 20230485";

export const ADDRESS_1 = "140 Robinson Road, #09-02, Tahir ";
export const ADDRESS_2 = "Building, Singapore 068907";

export const OPENING_HOURS = "Mon - Fri (9am -8pm)";

export const TOS_NAV_TEXT = "terms of service";
export const DPP_NAV_TEXT = "data protection policy";

export const REACH_OUT_TEXT = "For any queries please reachout to";

//Journey Text
export const JOURNEY_1_TITLE = "Incorporate a Company";
export const JOURNEY_1_CONTENT_1 = "Whether you are a local or a foreigner, starting a company in Singapore can be simple with a reliable compliance partner. Do you have a preferred company name for your startup?";
export const JOURNEY_1_CONTENT_2 = "Let us know and we will help you check the name availability for free!";
export const JOURNEY_1_LINK_TEXT= "Check your Company Name Availability ";

export const JOURNEY_2_TITLE = "Switch to Sequence";
export const JOURNEY_2_CONTENT_1 = "We offer transparent pricing for every stage of your business. We will also take care of all the transitions from your old company secretary to us so that you’ll never have to worry about misplaced documents again. All your files will be securely stored in our database, making it easier to organize and access.";
export const JOURNEY_2_CONTENT_2 = "Focus on your business and let us handle your corporate secretarial needs!";
export const JOURNEY_2_LINK_TEXT = "Explore More";

export const CS_DETAILS = [
    "Every business entity in Singapore must comply with periodic filing requirements with government agencies such as the Accounting and Corporate Regulatory Authority (ACRA), the Inland Revenue Authority of Singapore (IRAS), the Ministry of Manpower (MOM). ",
    "There are also mandatory filing requirements for some business entity transactions - for example in a company (which is governed by the Companies Act 1967), the appointment of certain officers or a special resolution must be lodged with ACRA within a specified period of time.", 
    "There are other rules and regulations governing each type of business entity, such as Limited Liability Partnerships (LLP), Variable Capital Companies (VCC) etc. ",
    "The table below sets out a non-exhaustive list of our corporate secretarial services and the prices thereof. Do reach out to us at info@sequence.sg for more information on the prices or other services that you may need."
];

export const AA_DETAILS = [
    "Every business has to keep a good sense of its numbers. As you would already be busy working on your core business activities, it can be overwhelming or just not worth your attention to manage all the accounting transactions and activities that need to occur daily.",
    "These include keeping the source documents and information, updating it into a real-time view of the financials and keeping it ready for mandatory annual reporting to the government, keeping track of the payables and receivables, making adjustments to the entries in the books etc. ",
    "The table below sets out a non-exhaustive list of our accounting services and the prices thereof. Do reach out to us at info@sequence.sg for more information on the prices or other services that you may need."
];




