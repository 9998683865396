import React from 'react';
import { Box, Flex, Text, Icon, Link, Image, VStack } from '@chakra-ui/react';
import { PRIMARY_COLOR, SECONDARY_TEXT_COLOR } from '../../constants/constant';

const itemSizes = {
  cardWidth:["98%","95%","400px","450px","450px","450px","450px"],
  cardHeight:["530px","450px","450px","400px","400px","400px","400px"],
  cardMarginX:["0px","0px","35px","40px","40px","40px","40px"],
  contentHeight:["350px","280px","280px","230px","230px","230px","230px"],
}

function JourneyCard(props) {
  const {
    title,
    iconImage,
    contents,
    linkText,
    onLinksClick
  } = props;

  return (
    <Box
      p={6}
      mx={itemSizes.cardMarginX}
      w={itemSizes.cardWidth}
      h={itemSizes.cardHeight}
      position="relative"
      bg="linear-gradient(145deg, rgba(72,61,139,0.7), rgba(75,0,130,0.5))"
      borderRadius="2xl" // More rounded corners
      boxShadow="lg"
      maxW="450px"
      textAlign="left"
      border={`1px solid ${PRIMARY_COLOR}`} // Optional border effect
      clipPath="polygon(0 0, 90% 0, 100% 12%, 100% 100%, 0% 100%)" // Slightly deeper clip
      style={{ borderTopRightRadius: '20px' }} // Adding rounded corners to clipped part
    >
      <Box mb="10px">
        <Image bgColor={PRIMARY_COLOR} borderRadius="10px" p="3px"src={iconImage} alt='iconImage' h="44px" w="44px" objectFit='contain'/>
      </Box>
      <Text fontWeight="bold" fontSize="xl" mb={2}>
        {title}
      </Text>
      <VStack h={itemSizes.contentHeight}>
        {contents.map((item)=>{
          return(
            <>
            <Text color={SECONDARY_TEXT_COLOR} mb={4}>
              {item}
            </Text>
            </>
          )
        })}
      </VStack>
      <Link color="purple.400" fontWeight="bold" onClick={() => {onLinksClick(6)}}>
        {linkText} &rarr;
      </Link>
    </Box>
  )
}

export default JourneyCard;
