import { Box } from '@chakra-ui/react'
import React from 'react'
import { LONG_BG_COLOR } from '../../constants/constant'

function TOS(props) {
  return (
    <>
    <Box
      bg={LONG_BG_COLOR}
      color="white"
      p={6}
      textAlign={"justify"}>
    <>
  {/* Hello world */}
  <div>
    <p>
      <strong>Terms of Service</strong>
    </p>
    <p> </p>
    <p>
      <strong>
        <em>Last Updated: 7-Oct-2024</em>
      </strong>
    </p>
    <p> </p>
    <p>
      These Terms of Service govern the use of our Platform and/or Services
      between Sequence SG BizCo Pte. Ltd. (and any of its affiliates) (“
      <strong>Sequence</strong>”, “<strong>we</strong>”, “<strong>us</strong>”,
      or “<strong>our</strong>”) and the User and/or the entity, company or
      organisation that the User represents, and shall apply to the use of any
      part of our Platform and/or Services, and such other applications or other
      services as we may provide from time to time. These Terms of Service
      include and incorporate by reference our Data Protection Policy (“
      <strong>Data Protection</strong> <strong>Policy</strong>”), and where
      applicable, together with any Letter of Engagement for our Services
      entered into between us and the User, shall form a binding agreement
      between us and the User (“<strong>Agreement</strong>”).
    </p>
    <p> </p>
    <p>
      By using our Platform and/or Services, the User acknowledges and agrees
      that (i) the User has read, understood, and agrees to be bound by the
      terms of this Agreement and our Privacy Policy, and that (ii) the User is
      of legal age and has the capacity to form a binding contract with
      Sequence.{" "}
    </p>
    <p>
      If the User is using our Platform and/or Services as a representative or
      agent of an entity, company or organisation, the User is agreeing to this
      Agreement on behalf of that entity, company or organisation and represents
      and warrants that the User is duly authorised to bind such entity, company
      or organisation, and in the absence of such authority, the User agrees to
      indemnify us for all losses, Damages, costs (including solicitors’ fees),
      claims, or actions brought against us, by such entity, company or
      organisation or otherwise, and the User shall also procure the
      ratification of the same, and shall be liable for any losses, Damages, or
      costs (including solicitors’ fees), claims, or actions incurred or brought
      against us as a result of any failure to ratify.
    </p>
    <p>
      If the User does not agree to be bound by the terms of this Agreement or
      our Data Protection Policy, is not of legal age, or does not have the
      capacity to enter into this Agreement or to bind the entity, company or
      organisation that the User represents, the User shall not use our Platform
      and/or Services.
    </p>
    <p> </p>
    <p>
      This Agreement may be revised from time to time by us, which we may notify
      the User of via the registered email address or by way of publication on
      our Platform. Continued use of our Platform and/or Services or after such
      revision to the Agreement had come into effect, shall constitute
      acceptance of the revised Agreement. If the User does not accept any part
      of such revisions, the User must immediately stop all use of our Platform
      and/or Services.
    </p>
    <p>
      Clause 1 (Services) and Clause 2 (Fees and Payment Terms) shall apply to
      Users who have subscribed for, purchased, or entered into a contract with
      us in whatever form, for our Services. For the avoidance of doubt, save as
      for Clause 1 (Services) and Clause 2 (Fees and Payment Terms), the rest of
      the terms of these Terms of Service shall apply to all other Users of our
      Platform, whether or not such User is a Registered User or guest User. In
      addition, the reference to our Services in such other terms in these Terms
      of Service shall be on a “where applicable” basis.{" "}
    </p>
    <p>
      1. <strong>Services</strong>
    </p>
    <p>
      1.1. Our Services are subject at all times to this Agreement and any other
      terms that may be agreed between Sequence and the User, with this
      Agreement taking precedence in case of any contradictions.
    </p>
    <p>
      1.2. In using our Services, the User undertakes to provide us with
      instructions, information, materials and documentation that are reliable,
      complete, accurate, detailed and up-to-date. We shall be entitled to rely
      upon the reliability, completeness, accuracy, details, and currency of all
      instructions and information provided to us by the User, or by others on
      behalf of the User, without independently verifying it, in the provision
      of our Services. We shall not be liable to the User in any manner
      whatsoever arising out of or in connection with any instructions or
      information that does not comply with the foregoing. In addition, the User
      agrees to indemnify Sequence and any of our affiliates (including any
      Sequence Personnel) against any and all losses, Damages, costs (including
      solicitors’ fees), claims, actions, penalties, or fines incurred by
      Sequence or any of our affiliates (including any Sequence Personnel)
      arising out of or in connection with performing our Services in accordance
      with or in reliance of any part of the User’s instructions or information
      provided. For the avoidance of doubt, the affiliates of Sequence and
      Sequence Personnel shall be entitled to enforce Clause 1.2 pursuant to and
      in accordance with the Contracts (Rights of Third Parties) Act 2001 of
      Singapore.
    </p>
    <p>
      1.3. The User shall provide us with all relevant or material documents,
      information, representations, endorsements, instructions, or approvals
      related to the User’s entity and related entities or individuals, in
      connection with the provision of our Services, in a clear and timely
      manner. We shall not be liable for any resulting consequences, and we
      shall not be taken to be in breach if such consequence arises, whether in
      part or in whole, from any inaccuracy, incompleteness, misleadingness, or
      defectiveness of such documents, information, representations,
      endorsements, instructions or approvals. In other words, the User is aware
      of the dependencies on the User’s part in relation to the provision of our
      Services, which include:
    </p>
    <p>
      (a) the timely approvals or endorsements, as required, by the managers,
      directors, officers or members of the entity that the User represents;
    </p>
    <p>
      (b) the timely provision of any change in circumstances or information in
      the User or in relation to the entity that the User represents that we
      would not be aware of unless updated by the User;
    </p>
    <p>
      (c) the timely provision of the User’s financial statements and other
      financial information, or that of the entity that the User represents.
    </p>
    <p>
      1.4. While we will strive to send out reminders and work with reasonable
      efforts to ensure that such dependencies do not prevent or delay us from
      providing our Services to the User, it is a condition precedent on the
      User’s part to manage and clear such dependencies before we are obliged to
      provide our Services, and where such dependencies prevent or delay us from
      providing our Services, we shall not be taken to be in breach. All
      consequences of failure to manage or clear such dependencies shall be
      borne by the User (e.g. penalties for late filing or additional
      applications to any government agencies or courts).
    </p>
    <p>
      1.5. In order to provide our Services, we may require additional
      information from the User including but not limited to supplemental
      agreements, know-your-client (“<strong>KYC</strong>”) due diligence forms,
      and other documents and information to comply with our policies and any
      applicable Law, regulation, guideline, or circular issued by any
      regulatory authority or other agencies or organisations, and for any other
      reason that Sequence may deem necessary from time to time. If the User
      does not provide such information that we may require, we are not obliged
      to perform any of our Services and shall not be deemed to be in breach of
      any obligations owing to the User.
    </p>
    <p>
      1.6. We reserve the right to refuse to provide any of our Services, and
      suspend, disable or terminate the User’s Account with us, should the
      result of our due diligence be unsatisfactory at our sole discretion.
    </p>
    <p>
      1.7. The User agrees that we are authorised to take any steps we deem
      necessary in our reasonable discretion to comply with any of the User’s
      specific requirements or other legal obligations. This includes soliciting
      for legal advice, engaging legal counsel and such other third parties on
      the User’s behalf, and which the User shall bear all costs and expenses
      arising thereof. These steps shall include any form of KYC processes and
      actions taken by us.
    </p>
    <p>
      1.8. The User agrees that there may be certain rectifications or actions
      required on the part of us (along with the full co-operation on the User’s
      part) in order for us to perform our Services and our obligations under
      this Agreement. Such rectifications or actions may not be apparent to us
      at the time of quoting or prior to the provision of our Services to the
      User. Examples include defective resolutions in the past, inaccurate
      statutory records, or accounting / financial errors, omissions, and
      backlogs. We reserve the right to send a revised quote that the User may
      accept or reject. If the User rejects the revised quote, we shall refund
      the sums paid to us after deducting any reasonable amounts for any work
      done or assessments made, and for any disbursements etc. Where there is
      any conflicting information or failure to provide information or to
      resolve any queries on your part, Sequence shall be entitled to take the
      publicly available information as the baseline for the handover to
      Sequence to perform the Services, notwithstanding that it is accepted by
      both Parties that such publicly available information would not be the
      complete information relating to you, the entity(ies) in scope of the
      Services, or in relation to the Services. It is agreed by you that such
      rectification works may be urgently required and that Sequence may, in
      certain circumstances, proceed with such works without express acceptance
      or approval and charge back to you for the fees and reimbursements as
      required.
    </p>
    <p>
      1.9. Notwithstanding any services provided by Sequence or any Sequence
      Personnel, you acknowledge and agree that Sequence is not a law firm or
      practice and neither of the Sequence Personnel is acting as a lawyer
      (whether under the definition of the Legal Profession Act 1966 or
      otherwise). You further acknowledge and agree that Sequence and/or any
      Sequence Personnel is not engaged by you to provide legal services, and
      shall not be taken to be providing any form of legal advice or services in
      their respective capacities. If you require legal advice and/or services,
      you acknowledge and agree that such advice and/or services are outside of
      the scope of our services, and you shall seek legal advice from the
      appropriate providers, such as a recognised legal practice.{" "}
    </p>
    <p>
      <strong>2. Fees and Payment Terms</strong>
    </p>
    <p>
      2.1. The User shall pay the fees for our Services in accordance with this
      Agreement. Our Services may be on an one-off, ad hoc, or on a subscription
      basis, depending on the type of Service. It is agreed and understood that
      we are not obliged to commence, provide, or continue to provide our
      Services if any of our invoices or requests for payment are outstanding or
      unpaid, and such discontinuation of the provision of our Services shall
      not be taken as a breach on our part. In addition, we reserve the right to
      suspend, disable or terminate the provision of our Services, the User’s
      Account and use of our Platform, in whole or in part.{" "}
    </p>
    <p>
      2.2. Unless otherwise mutually agreed in writing or stated thereon, all
      our fees in respect of our Services are payable 100% in advance, and we
      shall not begin providing any services until the fees are collected in
      full. All Services (or along with any subscriptions), unless otherwise
      expressly discontinued by the User, shall automatically renew thirty (30)
      days prior to the end of the applicable subscription period. The User
      authorises Sequence to charge any digital payment method for the fees for
      the renewed subscription. There shall be no refund after such renewal
      payment has been made. Sequence may either invoice the User via sending of
      a digital or physical invoice, or may notify the User of such information
      in the User’s Account via our Platform or email or otherwise, which shall
      have the same effect as sending an invoice to the User. Where there is a
      digital payment method prescribed by the User in our Platform, we shall be
      entitled to charge the payment method for the fees as per such invoices
      that may be sent to the User. Where there is no digital payment method
      applicable, the User shall make payment to us as per the payment terms via
      one of the means of payment below. Payment shall be due within thirty (30)
      calendar days of us sending the invoice. After fifteen (15) calendar days
      of such presentation, the amounts charged on the invoice will be
      conclusive if there are no disputes raised or clarifications requested on
      the invoice. We reserve the right to charge interest on any overdue
      amounts at the rate of 1% per month, or at the maximum rate allowed by the
      applicable Laws.
    </p>
    <p>
      2.3. Our payment details are set out in our invoices. We shall be entitled
      to invoice the User for our Services or additional services or fees
      (including disbursements) that are not contained in the subscriptions or
      displayed in the User’s Account.{" "}
    </p>
    <p>
      2.4. By paying our fees for our Services, the User shall be granted use of
      the corresponding functionality of our Platform.{" "}
    </p>
    <p>
      2.5. All payment of our fees must be paid without counterclaim and free
      and clear of any withholding or deduction or set-off, and all charges
      incurred, including bank charges, and connected with such remittance shall
      be wholly borne by the User. Sequence shall be entitled to set off any
      amounts owing to us from the User against any of our obligations towards
      the User.
    </p>
    <p>
      2.6. Payment of our fees does not automatically constitute our acceptance
      of such and the provision of Services.
    </p>
    <p>
      2.7. Sequence reserves the right to decline any subscription or purchase
      of any Service for any reason.
    </p>
    <p>
      2.8. All payments may be processed by an independent third-party payment
      processor. We exclude all liability for any loss or damage that might
      arise from the processing of User’s payment information, and the terms of
      service of that independent third-party payment processor shall apply,
      which shall be notified to the User at the time of signing up for the
      method of payment or otherwise on our Platform.
    </p>
    <p>
      2.9. The User acknowledges and agrees that if, for whatever reason, any
      payment is reversed or declined, where recurring payments are required,
      then the User’s liability to us will automatically be deemed a debt
      immediately due and payable. Any outstanding payments that the User owes
      us shall attract late payment interest of 1% per month or part thereof on
      a compounded basis from the due date until actual payment of the overdue
      amount, whether before or after judgment.
    </p>
    <p>
      2.10. Our fees are subject to any applicable tax which shall be borne by
      the User at the prevailing rate, and unless otherwise stated, shall be
      exclusive of disbursements, costs, and expenses, such as filing fees paid
      to ACRA, stamp fees, and other out-of-pocket expenses.
    </p>
    <p>
      3. <strong>User Account</strong>
    </p>
    <p>
      3.1. In order to use our Services through the Platform, the User is
      required to become a Registered User to access such features through the
      User’s Account.{" "}
    </p>
    <p>3.2. In creating an Account, the User agrees:</p>
    <p> (a) to provide Registration Data;</p>
    <p>
      (b) to maintain and promptly update the Registration Data to keep it true,
      accurate, current and complete;
    </p>
    <p>
      (c) not to create an Account using any false identity or information, or
      on behalf of someone other than yourself; and
    </p>
    <p>
      (d) if the User is entering into this Agreement on behalf of an entity,
      that the User has the full power and authority to enter into this
      Agreement.
    </p>
    <p>
      3.3. The User acknowledges and agrees that the User shall be solely
      responsible for maintaining the confidentiality and security of the User’s
      Account and associated access credentials and shall not allow another
      person to use the User’s Account or access credentials to access our
      Platform and Services. The User shall immediately notify us, whether via
      our Platform or otherwise, if the User suspects or becomes aware of any
      unauthorised use of the User’s Account or any other breach of security.
      Notwithstanding the foregoing, the User acknowledges and agrees that all
      actions taken on, and all operations of, our Platform that occur under or
      through the use of the User’s Account shall be attributable to the User
      (and the entity that the User represents, if the User is using our
      Platform on behalf of an entity), and the User shall be solely responsible
      for all such actions.
    </p>
    <p>
      3.4. We shall not be liable to the User for any harm or loss arising from
      or relating to the theft of the User’s Account, the disclosure of the
      User’s Account, or the use of the User’s Account by another person or
      entity.
    </p>
    <p>
      3.5. We reserve the right to temporarily or permanently suspend or
      terminate the User’s Account or impose limits on or restrict the User’s
      use of our Platform and/or Services with or without notice at any time for
      any or no reason including:
    </p>
    <p>
      (a) if the User provides any data (including Registration Data) that is
      untrue, inaccurate, not current or incomplete, or we have reasonable
      ground to suspect that any data (including Registration Data) the User
      provides is untrue, inaccurate, not current or incomplete;
    </p>
    <p>
      (b) if we have reasonable ground to believe the User has violated, or is
      about to violate, the Agreement, including any incorporated agreements,
      policies or guidelines, or any applicable Laws, or that the User’s Account
      may have been compromised;
    </p>
    <p>
      (c) if activities occur under the User’s Account which, in our sole
      discretion, would or might cause Damages or losses to us or any users
      (whether yourself or other users), impair our ability to provide our
      Services through our Platform, or infringe or violate any third-party
      rights (including Intellectual Property Rights);
    </p>
    <p>
      (d) if we are requested to do so by law enforcement agencies or other
      Governmental Authority; or
    </p>
    <p>
      (e) if we are required to do so due to unexpected technical or security
      issues or problems, or as part of our operations.
    </p>
    <p>
      3.6. The User hereby acknowledges and agrees that any or all data
      (including without limitation to Registration Data, User Data, and
      transaction data) and communications transmitted using or through our
      Platform may be monitored, captured, recorded and transmitted to the
      relevant Governmental Authorities as may be deemed necessary by us in our
      sole discretion and without further notice or request of consent from the
      User.
    </p>
    <p>
      <strong>4. Use of our Platform</strong>
    </p>
    <p>
      4.1. Subject to the terms of this Agreement, we grant the User a limited,
      revocable, non-exclusive, non-transferable, non-sublicensable, and
      non-assignable licence to use our Platform.
    </p>
    <p>
      4.2. The User does not acquire any rights, title, or interest in or to our
      Platform, other than a right to use our Platform in accordance with the
      limited licence granted hereunder and subject to all terms, conditions,
      and restrictions, under this Agreement or at the discretion of any
      authorised officers of Sequence. We reserve and shall retain our entire
      right, title, and interest in and to our Platform, including all
      Intellectual Property Rights therein or relating thereto.
    </p>
    <p>4.3. The User shall:</p>
    <p>
      {" "}
      (a) use our Platform in compliance with this Agreement and all applicable
      Laws;
    </p>
    <p>
      (b) obtain all consents, permissions, and authorisation required under
      applicable Law to input, provide, transfer, and make available data
      (including Personal Data) to us for the purposes of providing the User
      with use of our Platform; and
    </p>
    <p>
      (c) use all efforts to prevent unauthorised access to or use of our
      Platform, and where applicable, via the User’s Account, and notify us
      promptly of any such unauthorised access or use.
    </p>
    <p>4.4. The User shall not and shall not attempt to:</p>
    <p>
      (a) interfere with or disrupt the integrity or performance of our
      Platform, or any data, information and content contained thereon;
    </p>
    <p>
      (b) copy, adapt, modify, prepare derivative works based upon, transfer,
      transmit, or otherwise exploit our Platform, including any function or
      feature thereof;
    </p>
    <p>
      (c) use any manual or automated software, devices or other processes
      (including but not limited to spiders, robots, scrapers, crawlers,
      avatars, data mining tools or the like) to “scrape” or download any
      information and data from our Platform;
    </p>
    <p>
      (d) reverse engineer, disassemble, or otherwise attempt to derive or gain
      access to the source code or infrastructure of our Platform or any part
      thereof;
    </p>
    <p>
      (e) probe, scan, or test the vulnerability of our Platform, any of our
      systems or networks or breach any security or authentication measures, or
      otherwise attempt to benchmark our Platform;
    </p>
    <p>
      (f) upload, store on, or transmit or make available through our Platform
      any Harmful Codes;
    </p>
    <p>
      (g) remove, delete, alter or obscure any trademarks or any copyright,
      trademark, patent or other intellectual property or proprietary rights
      notices from our Platform, including any copy thereof;
    </p>
    <p>
      (h) post, upload, publish, submit or transmit or otherwise make available
      any User Data that the User does not have a right to make available under
      any Laws or under contractual or fiduciary relationships, that infringes,
      misappropriates or violates a third party’s patent, copyright, trademark,
      trade secret, moral or other Intellectual Property Rights, or rights of
      publicity or privacy, or that otherwise violates applicable Law or
      regulation;
    </p>
    <p>
      (i) use our Platform to defraud any person or entity (including without
      limitation sale or purchase, as the case may be, of stolen items, use of
      stolen credit/debit cards);
    </p>
    <p>
      (j) impersonate any person or entity, misrepresent yourself or the User’s
      affiliation with any person or entity;
    </p>
    <p>(k) engage in spamming or phishing;</p>
    <p>
      (l) engage in any other unlawful activities (including without limitation
      those which would constitute a criminal offence, give rise to civil
      liability, etc.) or encourage or abet any unlawful activities; or
    </p>
    <p>
      (m) engage in any activities that would otherwise create any liability or
      inconvenience for Sequence.
    </p>
    <p>4.5. The User acknowledges and agrees that:</p>
    <p>
      (a) we have no obligation to monitor the User’s access to or use of our
      Platform but have the right to do so for the purpose of operating our
      Platform, to ensure the User’s compliance with this Agreement or to comply
      with applicable Laws or the order or requirement of a court or other
      Governmental Authority;
    </p>
    <p>
      (b) we reserve the right, at any time and without prior notice, to
      terminate the licence granted to the User under this Agreement and
      disable, suspend, remove or terminate the User’s Account and use of our
      Platform (or any part thereof) should we, at its sole discretion, consider
      any of the User’s actions or operations to be in violation of this
      Agreement or to be otherwise harmful to our Platform or any other Users of
      our Platform; and
    </p>
    <p>
      (c) we may investigate and prosecute violations of this Agreement to the
      fullest extent of the Law and involve and cooperate with law enforcement
      authorities in prosecuting users who violate this Agreement.
    </p>
    <p>
      4.6. The User acknowledges and agrees that we may regularly update our
      Platform and reserves the right to add and/or substitute functionally
      equivalent features for any reason, without notifying the User. We reserve
      the right to remove, suspend, discontinue, alter, change, or update any
      component, feature, content, or function of our Platform without any
      liability towards the User. The User further agrees that all updates and
      changes to our Platform will be deemed part of our Platform and be subject
      to all terms of service of this Agreement.
    </p>
    <p>
      4.7. The User acknowledges and agrees that to the fullest extent permitted
      by Law, our Platform is provided by us on an “as-is” and “as-available”
      basis, with all faults or omissions. We expressly disclaims all other
      warranties, representations, and conditions of any kind, whether express
      or implied, with respect to this Agreement, our Platform or any results
      that may or may not be achieved by the use of our Platform by the User or
      the User’s authorised representatives, including without limitation all
      implied warranties of merchantability, quality, fitness for a purpose,
      non-infringement, accuracy, timeliness, or effectiveness, and any
      warranties arising from a course of dealing or usage or trade. We do not
      warrant that the operation of our Platform will be uninterrupted, Harmful
      Code-free, or error-free, and while we have used reasonable measures to
      maintain the confidentiality and access rights of the User’s information
      or data, the User acknowledges and agrees that we cannot guarantee the
      security or accessibility of such information or data. The User further
      acknowledges that we may use third-parties to host or otherwise operate or
      supplement our Platform and to store users’ information, and we make no
      representations or warranties and shall have no liability for such
      third-party services, to the fullest extent permitted by Law. We make no
      warranties regarding the accuracy or completeness of any information or
      data provided in connection with or obtained through the use of our
      Platform, and shall have no liability for any losses, Damages, costs,
      claims, actions, penalties, or fines arising from any use of such
      information or data.
    </p>
    <p>
      <strong>5. User Data</strong>
    </p>
    <p>
      5.1. Users hereby grant us a non-exclusive, royalty-free licence to access
      and use all of the User Data during the Term, and thereafter for the
      retention period for accounting and other records as prescribed by the
      Laws, for the purposes of:
    </p>
    <p>(a) providing use of our Platform and/or Services;</p>
    <p>
      (b) monitoring and improving the performance of our Platform and/or
      Services and other internal business purposes of ours, and marketing or
      branding efforts that may include using your name and logos and/or the
      entity(ies) in the scope of the Services;{" "}
    </p>
    <p>
      (c) complying with the retention of records as prescribed by the Laws.
    </p>
    <p>
      5.2. Users represent and warrant that they own or have the legal right and
      authority, and will continue to own or maintain the legal right and
      authority, to grant to us the licence set forth above.
    </p>
    <p>
      5.3. Users represent, warrant and agree that the User Data that they
      submit, post or display onto our Platform, shall:
    </p>
    <p>(a) be true, accurate, complete and lawful;</p>
    <p>(b) not be false, misleading or deceptive;</p>
    <p>
      (c) not contain information that is defamatory, libellous, threatening or
      harassing, obscene, objectionable, offensive, sexually explicit or harmful
      to minors;
    </p>
    <p>
      (d) not contain information that is discriminatory or promotes
      discrimination based on race, sex, religion, nationality, disability,
      sexual orientation or age;
    </p>
    <p>
      (e) not contain any link directly or indirectly to any other third party
      sites which include any content that may violate this Agreement;
    </p>
    <p>
      (f) not violate the rights of any party (including Intellectual Property
      Rights or other proprietary rights, rights of publicity or privacy, of any
      third parties);
    </p>
    <p>
      (g) not violate the terms of this Agreement or any applicable additional
      agreements; and
    </p>
    <p>
      (h) not violate any applicable Laws or promote any activities which may
      violate any applicable Laws.
    </p>
    <p>
      5.4. Unless otherwise agreed between us and the User in writing, the User
      acknowledges and agrees that we may, but shall have no obligation to,
      monitor and review User Data.
    </p>
    <p>
      5.5. We reserve the right to remove any data submitted, posted or
      displayed or transmitted through our Platform at any time. In addition,
      Sequence shall be entitled to collect, access, modify, distribute, audit,
      reproduce, delete or remove any User Data relating to the User freely and
      in perpetuity to the extent necessary to:
    </p>
    <p>(a) protect the User and other users of our Platform;</p>
    <p>
      (b) provide, protect, and improve our products and services, whether now
      in existence or in the future;
    </p>
    <p>(c) provide statistical analysis;</p>
    <p>(d) protect the integrity of any data held by us;</p>
    <p>
      (e) ensure the User’s compliance with this Agreement and any applicable
      Laws; and
    </p>
    <p>
      (f) disclose such data and information if required by Law or law
      enforcement authorities, to enforce this Agreement, or to protect our
      rights or those of other users.
    </p>
    <p>
      6. <strong>Technical Data</strong>
    </p>
    <p>
      {" "}
      It may be necessary for us to obtain, receive, or collect Technical Data
      from the User to provide the User with use of our Platform and/or
      Services. The User hereby grants to us a non-exclusive, worldwide,
      royalty-free, perpetual, irrevocable licence to:
    </p>
    <p>
      (a) compile, use, distribute, display, store, process, reproduce, or
      create derivative works of the Technical Data;
    </p>
    <p>
      (b) aggregate the Technical Data with other data for use in an anonymous
      manner for our marketing and sales or other activities; and
    </p>
    <p>
      (c) exploit aggregated Technical Data and/or provide aggregated Technical
      Data to third parties, so long as such data is presented in the aggregated
      format and does not identify the User.
    </p>
    <p>
      7. <strong>Internet / User Device / Third-Party Software</strong>
    </p>
    <p>
      {" "}
      The User acknowledges and agree that to use our Platform and/or Services,
      the User will need access to the Internet, and a computer, mobile phone,
      tablet, or other compatible computing devices capable of accessing the
      Internet and compliant with the specifications set forth for the use of
      our Platform (“<strong>User</strong> <strong>Device</strong>”). In
      addition, certain third-party software which is not incorporated into our
      Platform (including the operating system of the User Device and web
      browsers) (“<strong>Third</strong>-<strong>Party</strong>{" "}
      <strong>Software</strong>”) may be required to be properly installed onto
      such User Device for the User to use our Platform and/or Services. The
      User acknowledges that we have no control over the terms and conditions of
      the end user licence agreement provided with such Third Party Software,
      and shall under no circumstance be deemed a party to any agreement or
      arrangement between the User and any third parties. The User shall be
      fully responsible for obtaining Internet access, User Devices and entering
      into and maintaining the end user licence agreement for Third-Party
      Software at the User’s own cost, expense and risk. The User’s use of such
      separately acquired Third-Party Software and/or the User Device shall be
      in accordance with any applicable terms and conditions. We shall not be
      liable for, and the User shall release us from, any obligations or
      liabilities that may be owed by the User to any third parties under any
      agreement or arrangement between the User and such third parties. We shall
      not be liable to the User for the User’s inability to use our Platform
      and/or Services for any reasons not attributable to us, including the
      User’s lack of access to the Internet, or any issues with the User Device
      or Third-Party Software.{" "}
    </p>
    <p>
      8. <strong>Third-Party Materials</strong>
    </p>
    <p>
      8.1 We may display, include or make available third-party content or
      provide links to third-party websites or services (collectively, “
      <strong>Third-Party Materials</strong>”) on our Platform. The User
      acknowledges and agrees that any Third-Party Materials the User accesses
      through our Platform are entirely at the User’s own risk.
    </p>
    <p>
      8.2. Without prejudice to the foregoing, the User acknowledges and agrees
      that:
    </p>
    <p>
      (a) we may but shall have no obligation to monitor and review such
      Third-Party Materials, and shall not be responsible for such Third-Party
      Materials, including their accuracy, completeness, timeliness, currency,
      validity, copyright compliance, legality, decency, quality or any other
      aspect thereof;
    </p>
    <p>
      (b) we do not assume and will not have any liability or responsibility to
      the User or any other person or entity for any Third-Party Materials;
    </p>
    <p>
      (c) Third-Party Materials and links thereto are provided solely as a
      convenience to the User and the User accesses and uses them entirely at
      the User’s own risk and subject to such third parties’ terms and
      conditions;
    </p>
    <p>
      (d) we do not guarantee the continued availability of such Third-Party
      Materials, and may cease displaying, including or making available such
      Third-Party Materials at any time at its sole discretion without any
      liability to the User; and
    </p>
    <p>
      (e) we make no representations or warranties whatsoever, and shall not be
      liable for any Damage, liabilities, losses (including any loss of data or
      profits), or any other consequences that the User incurs or may incur
      arising out of or in relation to the User’s use of Third-Party Materials,
      any transactions completed in or through the same, nor for any contract
      entered into by the User with any third party.
    </p>
    <p>
      8.3. The User shall indemnify us against all costs, losses, liabilities
      and Damages which arise from any action or claim against us from third
      parties in respect of the User’s use of, integration and/or interface with
      Third-Party Materials and related data.
    </p>
    <p>
      8.4. In the provision of our Services, we may engage and incorporate third
      party service providers, software or tools. Notwithstanding anything in
      this Agreement, such usage and incorporation shall not be taken as a
      breach of any part of this Agreement, and shall be within our right to do
      so without the need to seek further approvals or notifications.
    </p>
    <p>
      9. <strong>Confidentiality</strong>
    </p>
    <p>
      9.1. “<strong>Confidential</strong> <strong>Information</strong>” means
      non-public data, information and other materials regarding the products,
      software, services, or business of a party (and/or, if either party is
      bound to protect the confidentiality of any third party’s information, of
      a third party) provided by us to the User where such information is marked
      or otherwise communicated as “proprietary” or “confidential,” or by its
      nature be reasonably considered confidential and/or proprietary. The User
      agrees that any information or data that we may provide to the User
      whether or not via our Platform (and related technical information) or in
      connection with our Services, is also Confidential Information.
    </p>
    <p>
      9.2. Confidential Information shall not include information which: (i) is
      already known to the User prior to disclosure by us; (ii) becomes publicly
      available without fault of the User; (iii) is rightfully obtained by the
      User from a third party without restriction on disclosure, or is approved
      for release by written authorisation from us; (iv) is independently
      developed or created by the User without the use of Confidential
      Information; or (v) is required to be disclosed by Law or governmental
      regulation, provided that, when legally permissible, the User provide
      reasonable notice to us of such required disclosure and reasonably
      cooperates with us in limiting such disclosure.
    </p>
    <p>
      9.3. Subject to the contrary in this Agreement, the User agrees to: (i)
      use the Confidential Information only to perform or exercise rights
      granted under this Agreement; (ii) treat all Confidential Information in
      the same manner as it treats its own proprietary information, but in no
      case with less than reasonable care; and (iii) disclose the Confidential
      Information only to the User’s employees and contractors who have a need
      to know such information, provided that any recipient shall be subject to
      obligations of confidentiality substantially similar to the terms of this
      Agreement. The User shall be liable for the non-compliance of an employee
      or contractor.
    </p>
    <p>
      9.4. The User’s obligations under this Clause 9 (Confidentiality) shall
      survive termination of this Agreement.
    </p>
    <p>
      10. <strong>Intellectual Property Rights </strong>
    </p>
    <p>
      10.1. The User acknowledges and agrees that we, and where applicable our
      third-party licensors, own all rights, title and interests (including
      without limitation to Intellectual Property Rights) in and to:
    </p>
    <p>(a) our Platform and/or Services;</p>
    <p>
      (b) any trademarks, service marks, trade names, domain names, website
      name, other significant brand features or specific descriptions which will
      allow a third party to identify us (collectively, “
      <strong>Sequence Proprietary Markings</strong>”);
    </p>
    <p>
      (c) all updates, derivatives and modifications developed or derived
      therefrom, including without limitation to any software, source and object
      codes, algorithms, data models (whether or not any of the foregoing have
      been developed using User Data), technology, web pages, text, pictures,
      images, audio, video, charts, layout design, and electronic documents, or
      customisation to our Platform and/or Services;
    </p>
    <p>
      (d) any reports or data generated by us in the course of providing the use
      of our Platform and/or Services to the User or from User Data uploaded or
      entered by the User;
    </p>
    <p>
      (e) any intangible ideas, residual knowledge, concepts, know-how and
      techniques related to or learned from our provision of our Platform and/or
      Services, including, without limitation, any intangible ideas, residual
      knowledge, concepts, know-how, and techniques related to any new features
      for our Platform and/or Services, whether or not created for the User; and
    </p>
    <p>
      (f) any operation and Technical Data relating to our Platform and/or
      Services (including without limitation to user Account information,
      Registration Data, operation records, and service orders),
    </p>
    <p>
      (collectively, the “<strong>Sequence Intellectual Property</strong>”).
    </p>
    <p>
      10.2. Other than the limited licence and use rights expressly set forth in
      this Agreement to Sequence Intellectual Property, we do not grant the User
      any rights to Sequence Intellectual Property and reserves all rights
      therein.
    </p>
    <p>10.3. The User shall not, and shall not permit any other person to:</p>
    <p>
      (a) access or use Sequence Intellectual Property except in accordance with
      the terms of and otherwise permitted under this Agreement;
    </p>
    <p>
      (b) display, use, apply for registration any Sequence Proprietary
      Markings;
    </p>
    <p>
      (c) represent to any other persons, that the User have the right to
      display, use or to otherwise dispose of Sequence Proprietary Markings;
    </p>
    <p>
      (d) modify, alter, remove, delete or destroy any Sequence Proprietary
      Markings placed upon or contained within our Platform and/or Services; or
    </p>
    <p>
      (e) take any action which would cause our Platform and/or Services or any
      part thereof to be placed in the public domain or to become open-source
      software.
    </p>
    <p>
      10.4. If there is any breach of this Clause, we shall be entitled
      immediately to any proceeds and ownership of any such intellectual
      property that may have been created or otherwise derived or re-formulated.
      If the ownership rights cannot be transferred for any reason, any
      exploitation rights shall be transferred accordingly in accordance with
      applicable Law. The User shall assist us in perfecting such rights, and
      any costs or expenses incurred in assisting us or incurred by us in
      connection with the perfection of such rights shall be borne by the User.
    </p>
    <p>
      11. <strong>Personal Data</strong>
    </p>
    <p>
      11.1. Notwithstanding our Data Protection Policy, the User represents,
      undertakes and warrants that:
    </p>
    <p>
      (a) for any personal data of individuals that the User will be or may be
      disclosing or disclose to us, that the User would have prior to disclosing
      such personal data to us obtained consent from the individuals whose
      personal data are being disclosed, to:
    </p>
    <p>
      (i) permit the User to disclose the individuals’ personal data to us for
      the purposes set out in our Data Protection Policy; and
    </p>
    <p>
      (ii) permit us (whether in Singapore or overseas) to collect, use,
      disclose and/or process the individuals’ personal data for the purposes
      set out in our Data Protection Policy;
    </p>
    <p>
      (b) the User shall notify the individuals whose personal data are
      disclosed to us of the relevant purposes for such disclosure;
    </p>
    <p>
      (c) any personal data of individuals that the User disclose or will be
      disclosing to us is accurate. Further, the User shall give us notice in
      writing as soon as reasonably practicable should the User be aware that
      any such personal data has been updated and/or changed after such
      disclosure;
    </p>
    <p>
      (d) the User shall at our request, assist us to comply with all applicable
      data protection legislation, including but not limited to the PDPA (the “
      <strong>Data Protection Laws</strong>”); and
    </p>
    <p>
      (e) for any personal data of individuals that the User will be or may be
      disclosing to us, that the User are validly acting on behalf of such
      individuals and that the User have the authority of such individuals to
      provide their personal data to us and for us to collect, use, disclose and
      process such personal data for the purposes set out in our Data Protection
      Policy.
    </p>
    <p>
      11.2. Without prejudice to the foregoing sub-provisions, the User shall
      ensure that the User complies with the Data Protection Laws, and that the
      User will not do anything and not omit to do anything that will cause us
      to be in breach of any provision or requirement of the Data Protection
      Laws, whether now or in the future. The User shall at our request,
      promptly do such things or execute such documents, as determined by us, in
      order to facilitate our compliance with the Data Protection Laws.
    </p>
    <p>
      11.3. Notwithstanding anything to the contrary, the User undertakes to
      indemnify and at all times hereafter to keep us (together with our
      respective officers, employees and agents) (each an “
      <strong>Injured</strong> <strong>Party</strong>”) indemnified against any
      and all direct and indirect losses, Damages, actions, proceedings, costs,
      claims, demands, liabilities (including full legal costs on a solicitor
      and own client basis) which may be suffered or incurred by the Injured
      Party or asserted against the Injured Party by any person or entity
      whatsoever, in respect of any matter or event whatsoever arising out of,
      in the course of, by reason of or in respect of:
    </p>
    <p>
      (a) any breach of any of the provisions in this Clause 11 by the User;
      and/or
    </p>
    <p>
      (b) any action or omission by the User, that causes us to be in breach of
      the Data Protection Laws or any other applicable Laws.
    </p>
    <p>
      For the avoidance of doubt, an Injured party shall have the right to
      enforce this Clause 11.3 pursuant to and in accordance with the Contracts
      (Rights of Third Parties) Act 2001 of Singapore.
    </p>
    <p>
      12. <strong>Indemnity </strong>
    </p>
    <p>
      12.1. The User undertakes, jointly and severally, to indemnify and keep
      Sequence or any Sequence Personnel indemnified on demand against any
      losses, Damages, claims, proceedings, charges, costs and expenses which
      they may suffer or incur, directly or indirectly, as a result of the
      provision of any part of our Platform and/or Services (including any
      omission on the User’s part to provide Sequence or Sequence Personnel, if
      any, with all relevant information to enable Sequence or Sequence
      Personnel to discharge their duties under applicable laws). For the
      avoidance of doubt, Sequence Personnel shall have the right to enforce
      this Clause 12 pursuant to and in accordance with Contracts (Rights of
      Third Parties) Act 2001 of Singapore.
    </p>
    <p>
      12.2. The scope of the subjects-matter of such indemnity includes any data
      protection, confidentiality, intellectual property, or other matters such
      as the User’s use of our Platform and/or Services, (ii) any breach of this
      Agreement by the User, and (iii) any third party claim arising out of or
      relating to User Data and our use thereof, including without limitation if
      it infringes any rights (including Intellectual Property Rights) of a
      third party. The scope of the indemnity shall specifically cover
      situations where the state of affairs or transactions or any act or
      omission of the entity(ies) creates any liability on Sequence from any
      third party, including government agencies and the like, provided that
      these were not wholly due to Sequence’s acts or omissions. An example is
      where incomplete or incorrect or inaccurate information, and failure to
      file or complete certain acts by a deadline in the past before Sequence
      took over to provide such Services.
    </p>
    <p>
      12.3. Such indemnity shall not be limited or reduced whether or not the
      relevant facts, matters, or circumstances giving rise to the breach by the
      User was known to Sequence or any Sequence Personnel. The rules of
      causation, remoteness of damage and mitigation of loss shall not apply to
      such indemnity claims.
    </p>
    <p>
      13.<strong> Limitation of Liability</strong>
    </p>
    <p>
      13.1. In no event shall we be liable for any special, consequential,
      incidental, or indirect damages, however caused and under any theory of
      liability arising out of this Agreement or otherwise, including without
      limitation loss of profits, loss of data, loss of goodwill, loss of
      reputation, loss of revenue, or loss of use, whether or not we have been
      advised of the possibility of such damages or circumstances, arising out
      of, resulting from, or relating in any way to (i) the User’s use or
      inability to use our Platform and/or Services; (ii) the cost of
      procurement of substitute services; (iii) unauthorised use, access, or
      alteration of User Data, our Platform and/or Services; (iv) statements or
      conduct of any third party (even if we were the ones who were liaising on
      behalf of you or the third party); or (v) any other matter related to our
      Platform and/or Services.
    </p>
    <p>
      13.2. Our aggregate liability for all losses or Damages (including
      interest thereon, if any) and costs suffered or incurred, directly or
      indirectly, by the User or by any third party that may have the benefit of
      or rely upon our work, under or in connection with this engagement,
      whether as a result of breach of contract or statutory duty, tort
      (including negligence), or any other act or omission by us, but excluding
      any losses, Damages, or costs that cannot lawfully be limited or excluded,
      shall be limited to one (1) time of the fees we have received for all
      Services (including additional services), or one (1) time of the fees for
      the trailing twelve (12) month period before the date of the earliest
      claim that is raised with us, whichever is the lower.
    </p>
    <p>
      13.3. The User hereby acknowledges and agrees that the provisions of this
      Agreement and the allocation of risk therein are essential elements of the
      bargain in our provision of our Platform and/or Services, without which we
      would not have provided our Platform and/or Services to the User or
      entered into this Agreement.
    </p>
    <p>
      14. <strong>Term and Termination</strong>
    </p>
    <p>
      14.1. Unless terminated earlier in accordance with the Agreement, this
      Agreement shall commence on the date when the User accepted this Agreement
      (including via the continued use or access to our Platform and/or Services
      as deemed acceptance) and remain in full force and effect while the User
      uses our Platform and/or Services.
    </p>
    <p>
      14.2. We shall be entitled to terminate this Agreement, and any agreement
      in relation to the provision of our Services, without penalty or liability
      or the obligation to refund any monies already paid to us, immediately by
      written notice in any of the following circumstances:
    </p>
    <p>(a) we are required to do so to comply with any applicable Law; </p>
    <p>
      (b) the User has breached any of their obligations under this Agreement
      and such breach is not remedied within five (5) calendar days of receipt
      of written notice requiring the User to do so;
    </p>
    <p>
      (c) the User has persistently violated their obligations under this
      Agreement relating to the security of our Platform or, in our sole
      opinion, the User’s activity is inherently dangerous and may damage the
      functionality and/or integrity of our Platform;{" "}
    </p>
    <p>
      (d) if, in our sole opinion, suspending, restricting or terminating the
      use of our Services is necessary to protect the security, functionality
      and/or integrity of our Platform;{" "}
    </p>
    <p>
      (e) the User becomes the subject of a petition in bankruptcy or any other
      proceeding (including the passing of any resolution) relating to
      insolvency, receivership, liquidation, assignment for the benefit of
      creditors;{" "}
    </p>
    <p>
      (f) the User is in breach of any part of this Agreement, or when Sequence
      anticipates any breach of any part of this Agreement;
    </p>
    <p>
      (g) any continuous due diligence processes reveal any activities,
      publicity, or relationships that we are not acceptable of, including but
      not limited to any potential links to AML/CFT activities, reputational
      issues, disputes; or{" "}
    </p>
    <p>
      (h) if the User engages in any illegal activity or is in any way
      non-compliant, or may become non-compliant, in relation to any regulated
      activities.
    </p>
    <p>
      14.3. Upon any termination of this Agreement, or any agreement for the
      provision of our Services:
    </p>
    <p>
      (a) all rights and licences granted to the User shall cease, and the User
      shall immediately discontinue use of our Platform and/or Services;
    </p>
    <p>
      (b) the User’s use of our Platform and/or may be suspended, discontinued
      or barred;
    </p>
    <p>
      (c) the User’s Account and related information, files and content
      associated with or inside the User’s Account (or any part thereof),
      including the User’s User Data, may be deleted from our database at our
      discretion. The User acknowledges and agrees that we will not have any
      liability whatsoever to the User for deletion of the User’s Account or the
      User’s User Data;{" "}
    </p>
    <p>
      (d) where applicable, the User shall promptly pay all amounts due to us as
      of the effective date of termination;
    </p>
    <p>(e) we shall not be liable to refund any amounts paid to it; and</p>
    <p>
      (f) where applicable, the User shall execute, or procure to be executed,
      all such documents and do all things to effect the resignation or
      replacement of Sequence Personnel which have been appointed as officers
      (e.g. director or secretary) of the User. If the resignation or
      replacement of Sequence Personnel which have been appointed as officers of
      the User is not effective, or cannot be effected, pursuant to any
      applicable law (e.g. where the Sequence Personnel is the sole director or
      resident director of the User), the User shall procure a suitable replace
      for such Sequence Personnel within 7 days of us notifying the User to do
      so, failing which we shall be entitled, without prejudice to all other
      rights under this Agreement or otherwise, to apply to any court for
      specific performance, an order that the User be wound up, damages, and the
      User shall be liable to reimburse us and/or the Sequence Personnel for all
      fees, charges, costs and expenses incurred thereby (including legal costs
      on a full indemnity basis).
    </p>
    <p>
      14.4. Any provision of this Agreement that expressly or by implication is
      intended to come into or continue in force on or after the termination of
      this Agreement shall remain in full force and effect.
    </p>
    <p>
      14.5. Any provision of this Agreement that expressly or by implication is
      intended to come into or continue in force on or after the termination of
      this Agreement shall remain in full force and effect, including without
      limitation to, Clause 4 (Use of our Platform), Clause 10 (Intellectual
      Property Rights), Clause 11 (Personal Data), Clause 12 (Indemnity), Clause
      13 (Limitation of Liability), Clause 14 (Term and Termination), Clause 15
      (Governing Law and Dispute Resolution), Clause 16 (General Provisions)
      <strong> </strong>and<strong> </strong>Clause 17 (Definitions).
    </p>
    <p>
      15. <strong>Governing Law and Dispute Resolution </strong>
    </p>
    <p>
      15.1. This Agreement shall be governed by and construed under the Laws of
      the Republic of Singapore.
    </p>
    <p>
      15.2. If any dispute arises under, out of, or in connection with this
      Letter, including any question regarding the existence, validity or
      termination of the same, the User shall in good faith try to settle it by
      emailing dispute@sequence.sg.
    </p>
    <p>
      15.3. In the event that the parties do not reach a settlement on a dispute
      after 60 days from the date of the email referred to in Clause 15.2, the
      dispute shall be referred exclusively to the courts of Singapore.
    </p>
    <p>
      <strong>16. General Provisions</strong>
    </p>
    <p>
      16.1. <strong>Entire Agreement.</strong> This Agreement supersedes all
      other prior and contemporaneous written and oral agreements and
      understandings between us and contain the complete agreement between the
      parties. This agreement may be modified only by subsequent written
      agreement of the parties. The User acknowledges that no promise has been
      made to the User other than those stated in this Agreement.
    </p>
    <p>
      16.2. <strong>Force Majeure.</strong> Sequence shall not be liable for any
      delay or failure to perform resulting from Force Majeure Events, including
      the inability to provide the User with access to or use of our Platform
      and Services. If a Force Majeure Event occurs that affects Sequence’s
      performance of its obligations under the Agreement, Sequence’s obligations
      under the Agreement will be suspended and the time for Sequence’s
      performance of its obligations will be extended for the duration of the
      Force Majeure Event.
    </p>
    <p>
      16.3. <strong>No Third-Party Beneficiaries.</strong> Save as expressly set
      out in this Agreement, no third party who is not a party to this Agreement
      (whether or not such person shall be named, referred to, or otherwise
      identified, or shall form part of a class of persons so named, referred
      to, or identified, in this Agreement) shall have any rights to enforce or
      rely upon any of the provisions of this Agreement whether under any Law
      (including the Contracts (Rights of Third Parties) Act 2001 of Singapore)
      or otherwise.
    </p>
    <p>
      16.4. <strong>Notice.</strong> Where Sequence requires that the User
      provide an email address, the User is responsible for providing Sequence
      with the User’s most current email address. In the event that the last
      e-mail address the User provided to Sequence is not valid, or for any
      reason is not capable of delivering to the User any notices required/
      permitted by the Agreement, Sequence’s dispatch of the e-mail containing
      such notice will nonetheless constitute effective notice.{" "}
    </p>
    <p>
      16.5. <strong>No Waiver.</strong> No failure or delay by either party in
      exercising any right under this Agreement shall constitute a waiver of
      that right.
    </p>
    <p>
      16.6. <strong>Assignment.</strong> This Agreement, and the User’s rights
      and obligations hereunder, may not be assigned, subcontracted, delegated
      or otherwise transferred by the User without Sequence’s prior written
      consent, and any attempted assignment, subcontract, delegation, or
      transfer in violation of the foregoing will be null and void. Sequence
      shall have the right to assign this Agreement without the User’s consent
      and without prior notice to the User. Subject to the foregoing, this
      Agreement shall be binding upon and shall inure to the benefit of the
      parties and their respective successors and assigns.
    </p>
    <p>
      16.7. <strong>Severability.</strong> If a court or tribunal of competent
      jurisdiction finds any provision of this Agreement invalid or
      unenforceable, that provision of this Agreement will be amended to achieve
      as nearly as possible the intent of the parties, and the remainder of this
      Agreement will remain in full force and effect.
    </p>
    <p>
      16.8. <strong>Language.</strong> This Agreement is made in the English
      language only, and any translation of these terms of service in another
      language shall not be binding upon the parties.
    </p>
    <p>
      16.9{" "}
      <strong>
        Novation or Assignment as part of Sequence Corporate Initiatives.{" "}
      </strong>
      You agree that there may be future corporate re-organisations that may
      require this Agreement to be novated between you, Sequence, and any target
      entity(ies) that Sequence may require, and that you will provide the
      necessary approvals and acts to effect such re-organisations by making the
      target entity(ies) the counter-party to this Agreement. In any case, there
      shall not be any disruption to the Services nor any additional fees
      payable in respect of such re-organisations on your end.
    </p>
    <p>
      17. <strong>Definitions</strong>
    </p>
    <p>
      17.1. In this Agreement, the following words and expressions shall have
      the meanings respectively assigned to them hereunder:
    </p>
    <p>
      “<strong>Account</strong>” means an account on our Platform for the access
      to our Platform or use of our Services.
    </p>
    <p>
      "<strong>Damages</strong>" means all direct and indirect liabilities,
      losses (including loss of profits, sales, business, revenue, business
      opportunity, goodwill, anticipated savings, reputation, or any other
      consequential losses), damages (including incidental, exemplary, special,
      punitive, and multiple damages or any other indirect damages), costs and
      expenses, fines and penalties, fees on a full indemnity basis and
      disbursements and costs of investigation, litigation, settlement,
      judgement and interest regardless of whether based upon warranty,
      contract, tort, statute, strict liability or otherwise.
    </p>
    <p>
      "<strong>Force</strong> <strong>Majeure</strong> <strong>Event</strong>"
      means any circumstance not within a party's reasonable control, including,
      without limitation (a) fire, storm, lightning, flood, drought, earthquake
      or other natural disaster or other similar acts of God; (b) epidemic or
      pandemic (including the COVID-19 pandemic or similar events); (c)
      terrorist attack, civil war, civil commotion or riots, war, threat of or
      preparation for war, armed conflict, imposition of sanctions, embargo, or
      breaking off of diplomatic relations; (d) any change in Laws or any action
      taken by a Governmental Authority, including without limitation imposing
      an export or import restriction, quota or prohibition, or failing to grant
      a necessary licence or consent; (e) fire, explosion or accident; (f) any
      labour or trade dispute, strikes, industrial action or lockouts; (g)
      non-performance by suppliers or subcontractors; and (h) the
      unavailability, interruption or failure of utility services such as
      electricity, gas, water, and telecommunications or major internet or cloud
      service providers or data centre.
    </p>
    <p>
      “<strong>GST</strong>” means the Goods and Services Tax of Singapore, or
      if the context suggests otherwise, the value-added tax of any other
      jurisdiction;
    </p>
    <p>
      "<strong>Governmental Authority</strong>" means any governmental,
      administrative, statutory, regulatory or self-regulatory, judicial or
      arbitral authority or body (including any division thereof), anywhere in
      the world with jurisdiction over the relevant affairs of Sequence or the
      User.
    </p>
    <p>
      “<strong>Harmful</strong> <strong>Code</strong>” means any computer code,
      files, scripts and programs, including any malware and/or software, that
      is intended or known to be harmful, destructive, disabling or which
      assists in or enables theft, alteration, denial of service, unauthorised
      disclosure or destruction or corruption of data, including viruses, worms,
      spyware, adware, keyloggers, trojans, ransomware and any new type of
      threats.
    </p>
    <p>
      “<strong>Intellectual Property Rights</strong>” means patents, trademarks,
      service marks, copyright, know-how, design rights, database rights, rights
      in computer software or source codes, rights in designs and inventions,
      trade secrets, confidential information, trade and business names and
      brands, internet domain names, any application (whether pending, in
      process or issued) for any of the foregoing and any other industrial,
      intellectual property or protected right similar to the foregoing (whether
      registered, registrable or unregistered) in any country and in any form,
      media, or technology now known or later developed.
    </p>
    <p>
      "<strong>Laws</strong>" means any applicable statute, regulation, by-law,
      ordinance or subordinate or subsidiary legislation in force from time to
      time, including the common law as may be applicable from time to time and
      any applicable industry codes or standards which are of a mandatory and
      binding nature.
    </p>
    <p>
      "<strong>Personal</strong> <strong>Data</strong>" means data, whether true
      or not, about an individual who can be identified (a) from that data; or
      (b) from that data and other information to which a party has or is likely
      to have access.
    </p>
    <p>
      “<strong>Platform</strong>” means the platform made available by Sequence,
      and all of its features and functions, including the means in which our
      Services are delivered via the platform, which the Users may access and,
      where applicable, use our Services. Our Platform shall incorporate our
      Website.{" "}
    </p>
    <p>
      “<strong>Registered</strong> <strong>User</strong>” means a User who has
      registered an Account.
    </p>
    <p>
      “<strong>Registration Data</strong>” means any information or data about
      yourself and/or the entity the User represents, which may be required by
      us or otherwise supplied to us.
    </p>
    <p>
      “<strong>Sequence Personnel</strong>” means Sequence (this term includes
      its affiliates), or any of its officers, employees, staff,
      representatives, and/or agents, and includes any such persons that have
      been appointed to act as a nominee director or nominee shareholder or
      company secretary.{" "}
    </p>
    <p>
      “<strong>Services</strong>'' means the services including any information
      and content available therein) or goods provided by Sequence on our
      Platform or otherwise. These services include anything that may be agreed
      between Sequence and the User (whether by way of quote and acceptance, or
      otherwise), and these services may be wholly or partially provided through
      our Platform or otherwise. Our Services shall include any further services
      as agreed between Sequence and the User at any point in time (including
      any services or goods provided before the effective date of this Agreement
      but that were contemplated to be governed by this Agreement), and these
      further services shall form part of our Services.{" "}
    </p>
    <p>
      “<strong>Technical</strong> <strong>Data</strong>” means system-specific
      data or information and other data relating to our Platform and Services.
    </p>
    <p>
      “<strong>User</strong>” means a person or individual using our Platform
      and/or Services, whether or not such person or individual is a guest
      visitor to our Platform or a Registered User or has engaged our Services
      independently of our Platform, and as the case may be, shall include the
      entity, company or organisation that the User represents.
    </p>
    <p>
      “<strong>User</strong> <strong>Data</strong>” means the data and
      information created, submitted to, or provided to Sequence by the User.
    </p>
    <p>
      “<strong>Website”</strong> shall mean{" "}
      <a href="http://www.sequence.sg/">www.sequence.sg</a> and all pages or
      sub-domains contained or therein, or linking therefrom.
    </p>
    <p>
      17.2. Any words following the terms “<strong>including</strong>”, “
      <strong>include</strong>”, “<strong>in</strong>{" "}
      <strong>particular</strong>”, or “<strong>for</strong>{" "}
      <strong>example</strong>” or any similar phrase shall be construed as
      illustrative and shall not limit the generality of the related general
      words.
    </p>
  </div>
</>

    
    </Box>
    </>
  )
}

export default TOS
