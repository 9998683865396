import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import "../../styles/common.css";
import PriceItemHeader from './PricingItemHeader';
import PriceItem from './PricingItem';
import { PRIMARY_COLOR } from '../../constants/constant';

function AdditionalPricing(props) {
    const {
        text1,
        text2,
        text3,
        priceList
    } = props;
  
    return (<>
            <VStack w="100%" direction="column" justify="flex-start" align="flex-start" pl="20px">
                <PriceItemHeader  text1={text1} text2={text2} text3={text3} showTitle={true}/>
                <>{
                    priceList?.map((element, index) => {
                        return(
                            <PriceItem element={element} key={index} isLastElement={priceList.length === (parseInt(index) + 1)}/>
                        )
                    })
                }</>
            </VStack>
    </>);
}

export default AdditionalPricing
