import React, { useEffect, useState } from 'react';
import { Box, Center, VStack} from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import EnquiryForm from '../components/Enquiry/EnquiryForm';
import { LONG_BG_COLOR } from '../constants/constant';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import TOS from '../components/Documents/TOS';
import DPP from '../components/Documents/DPP';


function DocumentView(props) {
  const location = useLocation();
  const [file, setFile] = useState(<TOS/>);
  useEffect(() => {
    if(location.pathname === "/tos"){
      setFile(<TOS/>);
    }else if(location.pathname === "/dpp"){
      setFile(<DPP/>);
    }
  },[location]);

  return (
    <Box bg={LONG_BG_COLOR}>
      <Header showMenu={false}/>
        <Center>
            <VStack w="1281px" h="100%"  mb="30px" mt="100px">
            {file}
            </VStack>
        </Center>
        <Box w="100%" h="800px" mt="80px">
            <EnquiryForm/>
        </Box>
        <Box display="flex" flexDirection="column" justifyContent="space-between">
            <Footer />
        </Box>
    </Box>
  )
}

export default DocumentView;
