import { createContext, useContext, useState, useEffect } from 'react';
import { useBreakpointValue } from '@chakra-ui/react';
import {
    LARGE_SCREEN,
    MEDIUM_SCREEN,
    SMALL_SCREEN, 
    XL_SCREEN,
    XXL_SCREEN,
    XXXL_SCREEN,
    XXXXL_SCREEN 
} from '../constants/constant';

const ScreenSizeContext = createContext();

export function useScreenSize() {
  return useContext(ScreenSizeContext);
}

export function ScreenSizeProvider({ children }) {
    const responsiveValue = useBreakpointValue({
        base: SMALL_SCREEN,
        md: MEDIUM_SCREEN,
        lg: LARGE_SCREEN,
        xl: XL_SCREEN,
        '2xl': XXL_SCREEN,
        '3xl': XXXL_SCREEN,
        '3xl': XXXXL_SCREEN,
      });

  const [screenSize, setScreenSize] = useState('');

  useEffect(() => {
    if (responsiveValue) {
      setScreenSize(responsiveValue);
    }
  }, [responsiveValue]);

  return (
    <ScreenSizeContext.Provider value={screenSize}>
      {children}
    </ScreenSizeContext.Provider>
  );
}
