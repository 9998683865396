import { Box, Center, Flex, HStack, Image, Spacer, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import "../../styles/common.css";
import happyRobo from '../../assets/happyRobo.png';
import supportIcon from "../../assets/supportIcon.png";
import transparentPricingIcon from "../../assets/transparentPricingIcon.png";
import digitalSolutions from "../../assets/digitalSolutions.png";
import PlanetSystem from './PlanetSystem';
import SupportCard from '../SupportCard/SupportCard';
import { 
    PRIMARY_COLOR, 
    SECONDARY_TEXT_COLOR, 
    SEQUENCE_BANNER_TEXT_2, 
    SEQUENCE_BANNER_TEXT_3,
    SUPPORT_TEXT_TITLE_1,
    SUPPORT_TEXT_TEXT_1,
    SUPPORT_TEXT_TITLE_2,
    SUPPORT_TEXT_TEXT_2,
    SUPPORT_TEXT_TITLE_3,
    SUPPORT_TEXT_TEXT_3,
    LONG_BG_COLOR
} from '../../constants/constant';

const itemSizes = {
    smallScreenComps:["flex","flex","flex","none","none","none","none"],
    bigScreenComps:["none","none","none","flex","flex","flex","flex"],
    bannerText1:["25px","30px","30px","40px","30px","40px","40px"],
    bannerText2:["18px","24px","24px","24px","18px","24px","24px"],
    roboImageSize:["218px","298px","298px","348px","348px","448px","448px"],
}

function Banner(props) {

    return (
        <><Center display={itemSizes.bigScreenComps}>
            <VStack>
                <Box px="20px" py="10px" 
                    zIndex={1000}
                    border={`2px solid ${PRIMARY_COLOR}`} 
                    borderRadius={"38px"}
                    bgColor={LONG_BG_COLOR}
                    boxShadow="0px 0px 10px rgba(255, 255, 255, 0.25), inset 0px 0px 10px rgba(255, 255, 255, 0.25)">
                    <Text color={SECONDARY_TEXT_COLOR} fontSize={"18px"}>modern day compliance</Text>
                </Box>
                <Spacer/>
                <Text color={"white"} fontSize={itemSizes.bannerText1} zIndex={1000}>Sequence helps you to focus<br/> on your co<span className='text-image-underline'>re busine</span>ss</Text>
                <VStack>
                    <PlanetSystem/>
                    <Text color={SECONDARY_TEXT_COLOR} fontSize={itemSizes.bannerText2} zIndex={1000}>{SEQUENCE_BANNER_TEXT_2}<br/>{SEQUENCE_BANNER_TEXT_3}</Text>
                    <HStack>
                        <Flex mt="-80px">
                            <SupportCard text={SUPPORT_TEXT_TEXT_1}
                                iconImage={supportIcon}
                                title={SUPPORT_TEXT_TITLE_1}/>
                        </Flex>
                        <Image src={happyRobo} alt='whatsapp' boxSize={itemSizes.roboImageSize} objectFit='contain' p="10px" zIndex={1000}/>
                        <Flex mt="80px">
                            <SupportCard text={SUPPORT_TEXT_TEXT_2}
                                iconImage={digitalSolutions}
                                title={SUPPORT_TEXT_TITLE_2}/>
                        </Flex>
                    </HStack>
                    <SupportCard text={SUPPORT_TEXT_TEXT_3}
                                iconImage={transparentPricingIcon}
                                title={SUPPORT_TEXT_TITLE_3}/>
                </VStack>
            </VStack>
        </Center>
        <Center display={itemSizes.smallScreenComps}>
            <VStack>
                <Box px="20px" py="10px" 
                    zIndex={1000}
                    border={`2px solid ${PRIMARY_COLOR}`} 
                    borderRadius={"38px"}
                    bgColor={LONG_BG_COLOR}
                    boxShadow="0px 0px 10px rgba(255, 255, 255, 0.25), inset 0px 0px 10px rgba(255, 255, 255, 0.25)">
                    <Text color={SECONDARY_TEXT_COLOR} fontSize={"18px"}>modern day compliance</Text>
                </Box>
                <Spacer/>
                <Text color={"white"} fontSize={itemSizes.bannerText1} zIndex={1000}>Sequence helps you to focus<br/> on your co<span className='text-image-underline'>re busine</span>ss</Text>
                <VStack>
                    <PlanetSystem/>                
                    <Flex w="95%" direction="column">
                        <Text whiteSpace="normal" color={SECONDARY_TEXT_COLOR} fontSize={itemSizes.bannerText2} zIndex={1000}>{SEQUENCE_BANNER_TEXT_2 + " " + SEQUENCE_BANNER_TEXT_3}</Text>
                    </Flex>
                    <Image src={happyRobo} alt='whatsapp' boxSize={itemSizes.roboImageSize} objectFit='contain' p="10px" zIndex={1000}/>
                    <SupportCard text={SUPPORT_TEXT_TEXT_1}
                                iconImage={supportIcon}
                                title={SUPPORT_TEXT_TITLE_1}/>
                    <SupportCard text={SUPPORT_TEXT_TEXT_2}
                                iconImage={digitalSolutions}
                                title={SUPPORT_TEXT_TITLE_2}/>
                    <SupportCard text={SUPPORT_TEXT_TEXT_3}
                                iconImage={transparentPricingIcon}
                                title={SUPPORT_TEXT_TITLE_3}/>
                </VStack>
            </VStack>
        </Center>
        </>)
}

export default Banner
