import React, { useEffect, useState } from "react";
import { 
    useToast, 
    Box, 
    Button, 
    Checkbox, 
    Flex, 
    FormControl,
    InputGroup, 
    Input,
    InputRightElement, 
    Select, 
    Text, 
    Textarea, 
    Image, 
    Center, 
    HStack, 
    VStack 
} from '@chakra-ui/react';
import contactRobo from "../../assets/contactRobo.png";
import { 
    EMPTY,
    GLOW_GREEN, 
    GRAY_700_COLOR, 
    PRIMARY_COLOR, 
    TEXTFIELD_BORDER_COLOR
} from '../../constants/constant';
import { countryPhoneCodes } from "../../constants/countryCodes";
import { sendEmail } from "../../services/serviceManager";
import { isValidEmail } from "../../utilities/utilities";
import { CheckIcon } from "@chakra-ui/icons";

const FlagOption = ({ data }) => (
    <Box display="flex" alignItems="center" p="2px 8px">
      <Text ml="5px">{data.phoneCode + "  " + data.name}</Text>
    </Box>
);

const itemSizes = {
    bigScreenComps:["none","none","none","flex","flex","flex","flex"],
    smallScreenComps:["flex","flex","flex","none","none","none","none"],
    bigScreenW:["70%","70%","70%","90%","90%","70%","70%"],
}

const EnquiryForm = () => {
    const toast = useToast();
    const [ fname, setFName ] = useState(EMPTY);
    const [ lname, setLName ] = useState(EMPTY);
    const [ email, setEmail ] = useState(EMPTY);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ checkMailingList, setCheckMailingList ] = useState(false);
    const [ countryCode, setCountryCode ] = useState("+65");
    const [ queryType, setQueryType ] = useState("General Inquiry");
    const [ description, setDescription ] = useState(EMPTY);
    const [ phoneNumber, setPhoneNumber ] = useState(EMPTY);
    const [ isCountryCodeValid, setIsCountryCodeValid ] = useState(true);

    useEffect(() => {
        setIsCountryCodeValid(true);
    },[countryCode]);

    useEffect(() => {
        const lowerCaseEmail = email?.toLowerCase();
        if(email && email !== lowerCaseEmail){
            setEmail(lowerCaseEmail);
        }
    },[email]);

    const sendDescriptionMail = () => {
        const countryCodeItem = countryPhoneCodes.find(element => element.phoneCode === countryCode);
        const isCountryCodeCorrect = countryCodeItem?true:false;
        const isPhoneEmpty = phoneNumber === EMPTY;

        if(!isPhoneEmpty && !isCountryCodeCorrect){
        setIsCountryCodeValid(false);
        }else{
            const query = `${queryType} : ${description}. add to mailing list ? ${checkMailingList? "Yes":"No"}`;
            const details = {
                query: query,
                email:email??"",
                name:fname + " " + lname??"",
                number: countryCodeItem?.phoneCode??"" + " - " + phoneNumber
        }

        sendEmail(onSuccess, onFailure, details);
        setIsLoading(true);
        }
    } 

    const resetValues = () => {
        setIsLoading(false);
        setEmail(EMPTY);
        setFName(EMPTY);
        setLName(EMPTY);
        setPhoneNumber(EMPTY);
        setCountryCode("+65");
        setDescription(EMPTY);
    }

    const onSuccess = (response) => {
        resetValues();
        toast({
            title: 'Success',
            description: "Query submitted!",
            status: 'success',
            duration: 5000,
            isClosable: true,
        });
    }

    const onFailure = (response) => {
        setIsLoading(false);
        toast({
            title: 'Failed',
            description: "Server error, please wait for sometime. and send your request again",
            status: 'error',
            duration: 5000,
            isClosable: true,
        });
    }
    return (
        <>
        <Center display={itemSizes.bigScreenComps}>
            <Flex justify="center" alignItems="center" w={itemSizes.bigScreenW} p={6}>
                <HStack w="100%" h="100%">
                    <VStack w="85%" px="20px">
                        <VStack w="100%" direction="column" justify="flex-start" align="flex-start">
                            <Text fontSize="46px" color="white" textAlign={"left"}>
                                Ready to turn your<br/> <Text as="span" color={GLOW_GREEN}>dreams</Text> into reality?
                            </Text>
                            <Text color="white">
                                Make it happen today! Contact our team to kickstart your company in Singapore and turn your ideas into reality.
                            </Text>
                        </VStack>
                        <HStack w="100%">
                            <Box w="50%" mr="20px">
                                <VStack w="100%">
                                    <FormControl mt={6}>
                                        <Flex gap={4}>
                                            <Input placeholder="First Name" bg="gray.700" color="white" focusBorderColor={PRIMARY_COLOR} onChange={(e) => {setFName(e?.target?.value)}} value={fname} borderColor={TEXTFIELD_BORDER_COLOR} _hover={{borderColor:PRIMARY_COLOR}} />
                                            <Input placeholder="Last Name" bg="gray.700"  color="white" focusBorderColor={PRIMARY_COLOR} onChange={(e) => {setLName(e?.target?.value)}} value={lname} borderColor={TEXTFIELD_BORDER_COLOR} _hover={{borderColor:PRIMARY_COLOR}} />
                                        </Flex>
                                    </FormControl>

                                    <FormControl mt={2}>
                                        <InputGroup>
                                            <Input placeholder='Email*' bg="gray.700"  color="white" type="email" focusBorderColor={PRIMARY_COLOR} onChange={(e) => {setEmail(e?.target?.value)}} value={email}  borderColor={TEXTFIELD_BORDER_COLOR} _hover={{borderColor:PRIMARY_COLOR}} />
                                            {isValidEmail(email)?<InputRightElement>
                                            <CheckIcon color={"white"}/>
                                            </InputRightElement>:null}
                                        </InputGroup>
                                    </FormControl>

                                    <FormControl mt={2}>
                                        <HStack>
                                            <Select color="white" 
                                                    borderColor={TEXTFIELD_BORDER_COLOR} 
                                                    _hover={{borderColor:PRIMARY_COLOR}} 
                                                    focusBorderColor={PRIMARY_COLOR} 
                                                    w="200px" 
                                                    mr="10px" 
                                                    bgColor={GRAY_700_COLOR}
                                                    placeholder='Select Country' 
                                                    onChange={(e) => {setCountryCode(e.target.value)}} value={countryCode}>
                                                {
                                                    countryPhoneCodes.map((element) => {
                                                    return(<>
                                                    <option style={{ backgroundColor: GRAY_700_COLOR, color: "white" }} key={element.code} value={element.phoneCode}><FlagOption data={element}/></option>
                                                    </>)
                                                    })
                                                }
                                            </Select>
                                            <Input type="tel" color="white" bgColor={GRAY_700_COLOR} borderColor={TEXTFIELD_BORDER_COLOR} _hover={{borderColor:PRIMARY_COLOR}} placeholder="Phone Number"  focusBorderColor={PRIMARY_COLOR} onChange={(e) => {setPhoneNumber(e?.target?.value)}} value={phoneNumber}/>
                                        </HStack>
                                    </FormControl>

                                    <FormControl mt={2}>
                                        <Select placeholder="How can we help you?" bg={GRAY_700_COLOR} borderColor={TEXTFIELD_BORDER_COLOR} _hover={{borderColor:PRIMARY_COLOR}} color="white" focusBorderColor={PRIMARY_COLOR} onChange={(e) => {setQueryType(e.target.value)}} value={queryType}>
                                            <option style={{ backgroundColor: GRAY_700_COLOR, color: "white" }} value="General Inquiry">General Inquiry</option>
                                            <option style={{ backgroundColor: GRAY_700_COLOR, color: "white" }} value="Support">Support</option>
                                            <option style={{ backgroundColor: GRAY_700_COLOR, color: "white" }} value="Feedback">Feedback</option>
                                        </Select>
                                    </FormControl>
                                    <Button mt={4} w="full" isLoading={isLoading} loadingText='Submitting' onClick={() => {sendDescriptionMail()}} isDisabled={(!isValidEmail(email) || (description.length === 0))} color={"white"} bgGradient={"linear(90deg, #763AF5, #A604F2)"} _hover={{bgGradient:"linear(90deg, #763AF5, #A604F2)"}}>
                                        Send your enquiry
                                    </Button>
                                    {(!isCountryCodeValid)?<Text color="red">Please select valid country code</Text>:null}
                                </VStack>
                            </Box>
                            <Box w="50%" h="100%">
                                <VStack w="100%" h="100%">
                                    <VStack w="100%" direction="column" justify="flex-start" align="flex-start">
                                        <FormControl>
                                            <Textarea h="200px" placeholder="Message" bg={GRAY_700_COLOR} borderColor={TEXTFIELD_BORDER_COLOR} _hover={{borderColor:PRIMARY_COLOR}} color="white" focusBorderColor={PRIMARY_COLOR} onChange={(e) => {setDescription(e.target.value)}} value={description}/>
                                        </FormControl>
                                    </VStack>
                                    <VStack w="100%" direction="column" justify="flex-start" align="flex-start">
                                        <Checkbox mt={2} colorScheme="purple" isChecked={checkMailingList} onChange={(e) => setCheckMailingList(e.target.checked)} color={"white"}>
                                            Join our Mailing List
                                        </Checkbox>
                                    </VStack>
                                </VStack>
                            </Box>
                        </HStack>
                    </VStack>
                    <Flex w="15%">
                        <Flex pt="300px">
                            <Image src={contactRobo} alt="robot" boxSize="200px"/>
                        </Flex>
                    </Flex>
                </HStack>
            </Flex>
        </Center>
        <Center display={itemSizes.smallScreenComps}>
            <Flex justify="center" alignItems="center" direction="column" w="100%" p={3}>
                <VStack w="100%">
                    <Text fontSize="20px" color="white" textAlign={"justify"}>
                        Ready to turn your<br/> <Text as="span" color={GLOW_GREEN}>dreams</Text> into reality?
                    </Text>
                    <Text color="white">
                        Make it happen today! Contact our team to kickstart your company in Singapore and turn your ideas into reality.
                    </Text>
                </VStack>
                <VStack w="100%">
                    <FormControl mt={6}>
                        <Flex gap={4}>
                            <Input placeholder="First Name" bg="gray.700" color="white" focusBorderColor={PRIMARY_COLOR} onChange={(e) => {setFName(e?.target?.value)}} value={fname} borderColor={TEXTFIELD_BORDER_COLOR} _hover={{borderColor:PRIMARY_COLOR}} />
                            <Input placeholder="Last Name" bg="gray.700"  color="white" focusBorderColor={PRIMARY_COLOR} onChange={(e) => {setLName(e?.target?.value)}} value={lname} borderColor={TEXTFIELD_BORDER_COLOR} _hover={{borderColor:PRIMARY_COLOR}} />
                        </Flex>
                    </FormControl>

                    <FormControl mt={2}>
                        <InputGroup>
                            <Input placeholder='Email*' bg="gray.700"  color="white" type="email" focusBorderColor={PRIMARY_COLOR} onChange={(e) => {setEmail(e?.target?.value)}} value={email}  borderColor={TEXTFIELD_BORDER_COLOR} _hover={{borderColor:PRIMARY_COLOR}} />
                            {isValidEmail(email)?<InputRightElement>
                            <CheckIcon color={"white"}/>
                            </InputRightElement>:null}
                        </InputGroup>
                    </FormControl>

                    <FormControl mt={2}>
                        <HStack>
                            <Select color="white" 
                                    borderColor={TEXTFIELD_BORDER_COLOR} 
                                    _hover={{borderColor:PRIMARY_COLOR}} 
                                    focusBorderColor={PRIMARY_COLOR} 
                                    w="200px" 
                                    mr="10px" 
                                    bgColor={GRAY_700_COLOR}
                                    placeholder='Select Country' 
                                    onChange={(e) => {setCountryCode(e.target.value)}} value={countryCode}>
                                {
                                    countryPhoneCodes.map((element) => {
                                    return(<>
                                    <option style={{ backgroundColor: GRAY_700_COLOR, color: "white" }} key={element.code} value={element.phoneCode}><FlagOption data={element}/></option>
                                    </>)
                                    })
                                }
                            </Select>
                            <Input type="tel" color="white" bgColor={GRAY_700_COLOR} borderColor={TEXTFIELD_BORDER_COLOR} _hover={{borderColor:PRIMARY_COLOR}} placeholder="Phone Number"  focusBorderColor={PRIMARY_COLOR} onChange={(e) => {setPhoneNumber(e?.target?.value)}} value={phoneNumber}/>
                        </HStack>
                    </FormControl>

                    <FormControl mt={2}>
                        <Select placeholder="How can we help you?" bg={GRAY_700_COLOR} borderColor={TEXTFIELD_BORDER_COLOR} _hover={{borderColor:PRIMARY_COLOR}} color="white" focusBorderColor={PRIMARY_COLOR} onChange={(e) => {setQueryType(e.target.value)}} value={queryType}>
                            <option style={{ backgroundColor: GRAY_700_COLOR, color: "white" }} value="General Inquiry">General Inquiry</option>
                            <option style={{ backgroundColor: GRAY_700_COLOR, color: "white" }} value="Support">Support</option>
                            <option style={{ backgroundColor: GRAY_700_COLOR, color: "white" }} value="Feedback">Feedback</option>
                        </Select>
                    </FormControl>
                    <FormControl>
                        <Textarea h="200px" placeholder="Message" bg={GRAY_700_COLOR} borderColor={TEXTFIELD_BORDER_COLOR} _hover={{borderColor:PRIMARY_COLOR}} color="white" focusBorderColor={PRIMARY_COLOR} onChange={(e) => {setDescription(e.target.value)}} value={description}/>
                    </FormControl>
                    <Checkbox mt={2} colorScheme="purple" isChecked={checkMailingList} onChange={(e) => setCheckMailingList(e.target.checked)} color={"white"}>
                        Join our Mailing List
                    </Checkbox>
                    <Button mt={4} w="full" isLoading={isLoading} loadingText='Submitting' onClick={() => {sendDescriptionMail()}} isDisabled={(!isValidEmail(email) || (description.length === 0))} color={"white"} bgGradient={"linear(90deg, #763AF5, #A604F2)"} _hover={{bgGradient:"linear(90deg, #763AF5, #A604F2)"}}>
                        Send your enquiry
                    </Button>
                    {(!isCountryCodeValid)?<Text color="red">Please select valid country code</Text>:null}
                </VStack>
            </Flex>
        </Center>
        </>
        
    );
};

export default EnquiryForm;
